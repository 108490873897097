import React from 'react'
import { Icon, Label } from 'semantic-ui-react'

function prepProfilelabel (props) {
    
  //take maker mongo ID and get profile data


    return ((props.userInfo) && false && (
        <Label as='a' color='white' image>
        {!props.landing ? (<img src={props.avatar} alt={props.userInfo} />) : 
        (<Icon name='user'/>)}
        {props.userInfo}
        {props.star==false ? (<></>) : (<Label.Detail><Icon name='star outline' /></Label.Detail>)}
        </Label>
      ))
}

export default prepProfilelabel