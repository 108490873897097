import React, { useEffect, useRef, useState } from 'react';
import { Grid, Image,Card, Icon, Label, Container, Segment, Table } from 'semantic-ui-react';
import styles from '../styles/components/Landing.module.css';
import { Outlet, Link } from 'react-router-dom';
import ProfileLabel from '../components/profile-label'
import Layout from '../components/Layout';
import { motion, useAnimation, isValidMotionProp, useAnimationControls, animate, easeInOut } from 'framer-motion';

import { useInView } from 'react-intersection-observer';

// import FadeInSection  from '../components/FadeSection'
// import { useInView, animated } from '@react-spring/web'



const LandingLayout = () => {

  return (<><header className={styles.header}>
        <div className={styles['header-container']}>
          <Link to="/landing">
            <img className={styles['logo-wrapper']} src={process.env.PUBLIC_URL + 'logo.png'} alt="logo" />
            <a style={{color: 'black', position:'relative', bottom:'30px', textAlign:'left'}}>beta</a>
          </Link>
          <div style={{textAlign:'center'}}></div>
          <div style={{position:'relative', top:'15px', textAlign:'center'}}>
          <Link style={{fontWeight:'900', position:'relative', left:'-60px', color:'black'}}to="/flowfinder">TRY BETA</Link>
          <a href={"https://twitter.com/nuhomeflow"}><Icon name={'twitter'}/></a></div>
        </div>
      </header></>)
    }

  //components for
  //screenshotdemo
  const FFdemo = () => {

    
    // https://www.youtube.com/embed/h3ew4tgYCfg;&autoplay=1;controls=1&amp&amp;showinfo=0&amp;modestbranding=1"
    return (<>
    
    <div className={styles['screenshot']}>
    <div className={styles['screenshot-image']}>

    <div className={styles["videoWrapper"]}>
      <iframe
      src={"https://www.loom.com/embed/a360e2162f7748f1a65c752344c609ca?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"}
      frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="nuHome beta demo"></iframe>
      </div>
    {/* <Image rounded src={process.env.PUBLIC_URL + 'landing-screen.png'}/> */}
    </div>
    </div>
   
    {/* </Link> */}
    </>)
  }
  
  //cards for rooms / functions
  const PopinCards = (props) => {
    // const refPop = useRef(null)
    // const isInView = useInView(refPop)
    // const cardControls = useAnimationControls()
    var springStart = 0
    const {ref, inView} = useInView()
    const cardControls = useAnimation()
    if (props.width<767) {
      springStart = 0
      console.log("SET")
    } else {
      if (props.direction=="right") {
        springStart = "10vw"
      } else {
        springStart = "-10vw"
      }
    }
    
    useEffect(() => {
      if (inView) {
        cardControls.start({ x: 0, opacity: 1, transition: {
          duration: 1,
          ease: "easeInOut",
        }})
      }
      if (!inView) {
        cardControls.start({ x: springStart, opacity: 0, transition: {
          duration: 1,
          ease: "easeInOut",
        }})
      }
    }, [inView])



    // let springStart = 0
    // if (props.width < 767) {
    //   springStart = 0
    //   console.log("SET")
    // } else {
    //   springStart = 200
    // }
    // if (props.direction=="right") {
      
    // } else {
    //   springStart = -1*springStart
    // }
    // const [ref, springs] = useInView(
    //   () => ({
    //     from: {
    //       opacity: 0,
    //       x: springStart,
    //     },
    //     to: {
    //       opacity: 1,
    //       x: 0,
    //     },
    //   }),
    //   {
    //     rootMargin: '-30% 0%',
    //   }
    // )

    return (<>
    <div ref={ref}>
    <motion.div
    initial={{ x: springStart, opacity:0}}
    animate={cardControls}
    transition={{type:easeInOut, duration: 1}}
    className={styles['popin-card']}>
    <div className={styles['center-text-card']}>{props.title}</div>
      <div>
        <Image style={{width:'inherit', 
}} src={props.img}/>
        </div>
        </motion.div></div>
    </>)
  }
  //customer review card
//
  //profile badge
//
const UserReview = () => {

  return (
    <div className={styles['review-box']}>
      “nuHome makes it easy to plan, design, and build a smart home. It's like Pinterest for your home automation.”
      <br/><br/><br/><br/><ProfileLabel landing={true} userInfo={"Abhi Kamble"} star={false}/></div>
  )
}
//three vertical bullets
//font style color into CSS

  //footer banner
  const FooterLanding = () => {

    return (
      <div style={{ position: 'relative',zIndex: 1 }} fluid className={styles['footer']}>
        <Grid.Row centered>
      <a href={"https://twitter.com/nuhomeflow"} ><Icon size='big' name={'twitter'}/></a>
      <a style={{color: 'grey'}}>Contact support@nuhome.solutions</a>
        </Grid.Row>
      </div>
    )
  }

  //content for tri bar

const SingleTriPane = (props) => {

  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <div className={styles['paragraph-subheader']}>{props.title}</div>
        </Grid.Column>
        <Grid.Column>
          <Image style={{objectFit:'contain', width:'auto'}}src={props.img}/>
          </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
        {props.text}</Grid.Column>
      </Grid.Row>
    </Grid>
  )
}


// <Image style={{position: 'relative', top:'-100px', zIndex: 0 }} fluid src={process.env.PUBLIC_URL + 'roof.png'} />

// style={{
//   display: 'flex',
//   alignItems: 'stretch',
//   position: 'relative',
// top:'0px',
// right:'0px',
// left:'0px',
// backgroundColor:'yellow'}}
  //button for start building

const Landing = () => {
  // const springs = useSpring({
  //   from: { x: 0 },
  //   to: { x: 100 },
  // })
  const [width, setWidth]   = useState(window.innerWidth);
  const updateDimensions = () => {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const roofOffset = 0      //-720
  
    return (
      <>
      <LandingLayout/>
      <div>
        <div>
      <Grid stackable>
          <Grid.Row columns={2}>
              <Grid.Column width={9} centered>
                <Container className={styles['para-container']}>
<div className={styles['paragraph-header']}>Smart home automation is complicated and the options are endless.</div>
<Grid.Row><br/><br/></Grid.Row>
<div className={styles['paragraph-header-blue']}>
nuHome is a library of home automation ideas shared by the community.</div><Grid.Row><br/><br/></Grid.Row><div className={styles['paragraph-header']}>
Start with the automations you want and the devices you have, we'll suggest the best options to build it.</div>
                </Container>
              </Grid.Column>
              <Grid.Column centered className={styles['para-container']} width={7}>
                <div className={styles['image-right']}>
              <Image src={process.env.PUBLIC_URL + 'landing-devices.png'}/></div>

                </Grid.Column>
          </Grid.Row>
  <Grid.Row><br/><br/><br/><br/><br/><br/><br/><br/></Grid.Row>
  <div className={styles['selector']}>
    <Link to="/flowfinder">
    <div className={styles['center-header']}>
    {/* <div className={styles['screenshot-middle']}> */}
    nuHome beta available now!
    {/* </div> */}
    </div></Link></div>
    <Grid.Row fluid centered><br/></Grid.Row>
    
    <Grid.Row fluid centered><Grid.Column centered>

      <div className={styles['light-weight']}>
      Is your home working for you?
    </div></Grid.Column></Grid.Row>
      <Grid.Row centered>
      <Grid.Column>
      <FFdemo />     </Grid.Column>
    </Grid.Row>

<Grid.Row centered>
  <Grid.Column style={{textAlign:'center',width:"auto"}} stretched>
  <Image style={{margin: 'auto', width:"192px"}} src={process.env.PUBLIC_URL + 'logo512.png'}/>
  <div className={styles['paragraph-subheader-blue']}>Plan your dream smart home.</div></Grid.Column>
</Grid.Row>
<Grid.Row centered>
<Grid.Column width={12}>
<ol style={{ listStyleType: 'decimal' }}>
            <li className={styles['bullet-head']} style={{fontWeight:'900'}}><div>Favorite the home automations you want to your wishlist</div><br/></li>
            <li className={styles['bullet-head']} style={{fontWeight:'900'}}><div>Add your existing devices available at home - 'smart' or not</div><br/></li>
            <li className={styles['bullet-head']} style={{fontWeight:'900'}}><div>Check out the recommended set of devices and build options - choose DIY or installation service</div><br/></li>
            <div style={{textAlign:'center'}} className={styles['bullet-head']}><br/>
            Our intelligent home planner helps you achieve your automation needs while reusing existing or old equipment - win / win</div>
          </ol>

  </Grid.Column></Grid.Row>
      <Grid.Row centered>
      <Grid.Column>
      
      <div className={styles['center-header']}>
      DISCOVER NEW IDEAS IN

</div>
      </Grid.Column>
    </Grid.Row>

      <Grid.Row columns={2} centered>
        <Grid.Column width={4}>
        <PopinCards width={width} direction={'left'}img={process.env.PUBLIC_URL + 'landing-security.png'} title={"SECURITY"}/>
        </Grid.Column>
        <Grid.Column width={4}>
        <PopinCards width={width} direction={'right'} img={process.env.PUBLIC_URL + 'landing-energy.png'} title={"ENERGY"}/>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={2} centered>
        <Grid.Column floated='right' width={4}>
        <PopinCards width={width} direction={'left'} img={process.env.PUBLIC_URL + 'landing-safety.png'} title={"SAFETY"}/>
        </Grid.Column>
        <Grid.Column floated='left' width={4}>
        <PopinCards width={width} direction={'right'} img={process.env.PUBLIC_URL + 'landing-tv.png'} title={"PLAY"}/>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row><br/></Grid.Row>

      <Grid.Row centered columns={3}>
        <Grid.Column>
        <SingleTriPane text={(<div>
          <div className={styles['bullet-paragraph']}>Find products that respect customers  data</div>
          <div className={styles['bullet-paragraph']}><br/>Enhance home safety for sensitive groups like kids, elderly, and others</div>
          <div className={styles['bullet-paragraph']}><br/>Check out the safety and security categories in nuHome beta for more</div>
        </div>)} title={"Privacy & Safety"} img={process.env.PUBLIC_URL + 'landing-icon-privacy.png'}/></Grid.Column>
        <Grid.Column>
        <SingleTriPane text={(<div>
          <div className={styles['bullet-paragraph']}>nuHome can optimize any smart home build, saving you time and money</div>
          <div className={styles['bullet-paragraph']}><br/>Find ways to reuse old computers and electronics</div>
          <div className={styles['bullet-paragraph']}><br/>Brand-neutral recommendations</div>
        </div>)} title={"Compatibility & Reuse"} img={process.env.PUBLIC_URL + 'landing-icon-reuse.png'}/>
        </Grid.Column>
        <Grid.Column>
        <SingleTriPane text={(<div>
          <div className={styles['bullet-paragraph']}>Squeeze every bit of functionality from your smart home</div>
          <div className={styles['bullet-paragraph']}><br/>nuHome is powered by the wisdom of the crowd</div>
          <div className={styles['bullet-paragraph']}><br/>Optimize for cost or ease of installation</div></div>)} title={"Maximize Home Features"} img={process.env.PUBLIC_URL + 'landing-icon-homefeature.png'}/>
        </Grid.Column>
      </Grid.Row>
            <Grid.Row centered>
      <Grid.Column width={10}>
      <Grid.Row><br/></Grid.Row>
      <div className={styles['center-header']}>
      Find useful home automations and the right mix of products, for any room
      </div>
      </Grid.Column>
    </Grid.Row>
    {/* <div className={styles['background-roof']}> */}
      <Grid.Row><br/><br/><br/><br/><br/><br/><br/></Grid.Row>
      <Grid.Row><br/><br/><br/><br/><br/><br/><br/></Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column floated='right' width={6}>
        <PopinCards direction={'left'} img={process.env.PUBLIC_URL + 'landing-kitchen.png'} title={"KITCHEN"}/>
        </Grid.Column>
        <Grid.Column floated='left' width={6}>
        <PopinCards direction={'right'} img={process.env.PUBLIC_URL + 'landing-office.png'} title={"OFFICE"}/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column floated='right' width={6}>
        <PopinCards direction={'left'} img={process.env.PUBLIC_URL + 'landing-livingroom.png'} title={"LIVING ROOM"}/>
        </Grid.Column>
        <Grid.Column floated='left' width={6}>
        <PopinCards direction={'right'} img={process.env.PUBLIC_URL + 'landing-bedroom.png'} title={"BEDROOM"}/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row><br/><br/><br/></Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
        <div className={styles['fade-image']}><Image circular rounded src={process.env.PUBLIC_URL + 'landing-network.png'}/></div></Grid.Column><Grid.Column>
        <div className={styles['paragraph-header-center']}>
          Don’t guess while integrating your smart home devices.<br/>
          <br/>nuHome’s database of smart home flow ideas is tested by the community.
        </div>
        </Grid.Column>
        <Grid.Column>

        </Grid.Column>
      </Grid.Row>
      <Grid.Row><br/><br/><br/></Grid.Row>

  <Grid.Row columns={2}>
  <Grid.Column width={10}>
    <div className={styles['bullet-head']}>⭐️ Discover new smart home features with stuff you already have at home!
    <br/><br/>⚙️ Share tweaks and features that work well for you, and stay up-to-date in the fast changing world of IoT
    </div>
  </Grid.Column>
  <Grid.Column stretched width={6}>
    <div styles={{padding:'200px'}}><UserReview/></div>
  </Grid.Column>
</Grid.Row>
    <Grid.Row columns={2}>
    <Grid.Column stretched width={8}>
        <Image src={process.env.PUBLIC_URL + 'logoHD.png'}/>
      </Grid.Column>
      <Grid.Column width={8}>
<div className={styles['center-text']}>Make your home work for you.</div>
</Grid.Column>
    </Grid.Row>
<FooterLanding roofOffset={roofOffset}/>
    </Grid></div></div>
</>);}

export default Landing;