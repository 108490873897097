import styles from '../styles/components/SignIn.module.css'
import { useState } from 'react'
import { useSignInEmailPasswordless, useSignInAnonymous, useNhostClient} from '@nhost/react'
import Input from './Input'
import Spinner from './Spinner'
import GoogleLogo from '../assets/google-logo.png'
import Spy from '../assets/spy.png'

const SignIn = () => {

  const nhost = useNhostClient()

  const [email, setEmail] = useState('')

  const { signInEmailPasswordless, isLoading, isSuccess, isError, error } =
    useSignInEmailPasswordless()

  // const { signInAnonymous, isAnonLoading, isAnonSuccess, isAnonError, Anonerror } =
  // useSignInAnonymous()

  const handleOnSubmit = (e) => {
    e.preventDefault()
    signInEmailPasswordless(email)
    }
    


  const disableForm = isLoading || isSuccess

  return (
    <div className={styles.container}>
      <div className={styles.card}>
      <a href="/landing">
        <div className={styles['logo-wrapper']}>
          <img src={process.env.PUBLIC_URL + 'logo.png'} alt="logo" />
        </div>
      </a>
        {(isLoading || isSuccess) ? (
          <p className={styles['verification-text']}><br/><br/>
            Please check your mailbox for a secure Magic Link to login.
          </p>
        ) : (
          <form onSubmit={handleOnSubmit} className={styles.form}>
            <br/>
            <Input
              type="email"
              label="Sign in or sign up to create an account"
              placeholder='Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={disableForm}
              required
            />

            <button type="submit" disabled={disableForm} className={styles.button}>
              {isLoading ? <Spinner size="sm" /> : 'Sign in'}
            </button>
            <div style={{textAlign:'center'}}>OR</div>
            <div onClick={() => {
                nhost.auth.signIn({
                  provider: 'google'
                })
              }} className={styles['button-google']}>
              <div><img 
              style={{width: '25px', borderRadius: '50%'}} src={GoogleLogo}/></div>
              <div>&ensp;&ensp;{"Sign in with Google"}</div>
            </div>
            {/* <div style={{textAlign:'center'}}>OR</div>

            <div onClick={() => {signInAnonymous()
              }} className={styles['button-anon']}>
              <div><img 
              style={{width: '25px', borderRadius: '50%'}} src={Spy}/></div>
              <div>&ensp;&ensp;{"Sign in Anonymously"}</div>
            </div> */}

            {/* {isError ? <p className={styles['error-text']}>{error?.message}</p> : null} */}
          </form>
        )}
      <br/>
        {/* <a style={{color: 'grey'}} href={process.env.PUBLIC_URL + '/privacy.html'}>Terms & Conditions</a> */}
      </div>
    </div>
  )
}

export default SignIn