
import React from 'react'
import { Divider, Header, Dropdown } from 'semantic-ui-react'


export default function MenuExampleSecondary (props) {

  const menuOptions = [
    {
      key: 'all',
      text: 'ALL',
      value: 'all',
      icon: 'star',
    },
    {
      key: 'energy',
      text: 'ENERGY',
      value: 'energy',
      icon: 'battery half'
    },
    {
      key: 'entertainment',
      text: 'ENTERTAINMENT',
      value: 'entertainment',
      icon: 'tv'
    },
    {
      key: 'security',
      text: 'SAFETY & SECURITY',
      value: 'security',
      icon: 'plus circle'
    },
    {
      key: 'other',
      text: 'OTHER',
      value: 'other',
      icon: 'circle'
    }
  ]
  
  const DropdownExampleInline = () => (
    <span><div>
      <Header style={{letterSpacing:'1px'}}><Dropdown
        inline
        value={props.activeItem}
        options={menuOptions}
        onChange={props.handleMobileMenu}
        styles={{textTransform: 'uppercase'}}
      /> HOME AUTOMATIONS</Header><Divider/></div>
    </span>
  )

    return (
      <><DropdownExampleInline/><br/></>
    )
}