import _ from 'lodash'
import React, { useState } from 'react'

import { gql, useQuery } from "@apollo/client";

const GET_HOME_FLOWS = gql`
    query HF ($userID: [String!]) {
      homeFlows (userID:$userID){
        _id
        basename
        feature
        ratings{
          degree
          criteria
        }
        createdby{
          address
          alias
        }
      }
    }
    `;

function QueryHomeFlows (userID) {
  const {data: allHomeFlows, loading: loadingHomeFlows,
    error: errorHomeFlows, refetch: refetchHomeFlows} = useQuery(GET_HOME_FLOWS,
     {variables: { userID }});
 

  if (loadingHomeFlows) { return ('Loading...') };
  if (errorHomeFlows) { return ('ERROR')};


const homeFlows = allHomeFlows.homeFlows.map(flow => {
  return {
  basename: flow.basename,
  feature: flow.feature,
  ratings: flow.ratings,
  makerAddr: flow.createdby.address,
  makerAlias: flow.createdby.alias,
  id: flow._id
  }});
return {homeFlows, loadingHomeFlows, refetchHomeFlows}

}

export default QueryHomeFlows