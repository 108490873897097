import React, { useState, useEffect } from 'react';
import { Icon, Header, Table, Card, Grid, Container, Dimmer, Loader } from 'semantic-ui-react';
import ReactFlow, { Background, Controls, MiniMap } from 'react-flow-renderer';
import { useParams, useLocation, useOutletContext } from "react-router-dom"
import { RiseLoader } from 'react-spinners'

import CreateUpdateEvent from '../components/update-event'
import buttonStyles from '../styles/components/Button.module.css';

import BaseFeatureTable from './base-features-table'
import FlowDetailsQuery from './flow-details-query'
import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import Color from 'color';
import dagre from 'dagre';

import ProductSpecQuery from '../components/product-spec-query';
import PreCompQuery from '../components/product-class-query-precomp';

import ProductTable from '../components/product-table';
import SpecTable from '../components/product-spec-cards';


const reFormComp = (comp) => {
  var tempComp = [[]]
  comp.forEach(async (xProd,xIndex) => {
    var tempY = []
    await xProd.forEach((yProd, yIndex) => {

      tempY.push(yProd._id)
    })
    tempComp[0].push({text: tempY})
  })
  return {tempComp}
}

//flow re arrange dagre function
const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

//page for each base Flow ID, lists features, and details/tags for each level
//button to add new feature ie 'make' page
//will hold instructions and hover over 'add cart' button over names
//edit option for permissioned users

const GET_EVENT_DEETS = gql`
  query listProductClasses ($eventIDs: [String!]) {
    findEvent(eventID:$eventIDs){
          _id
          eventtitle
          actiontrigger
    }
  }
`;

const FULL_EVENT_DEETS = gql`
query listProductClasses ($eventIDs: [String!]) {
  findEvent(eventID:$eventIDs){
        _id
        eventtitle
        actiontrigger
        compatibleprod{
          _id 
        }
        uservariables
        voicecmds{
          _id
          command
        }
  }
}
`;
const HOME_PRODUCTS = gql`
query HomeProducts($userId: String!) {
  mongusers(userID: $userId) {
    homeProducts {
      make
      model
      productClass {
        _id
      }
    }
  }
}
`;
function QueryHomeProducts (mid) {

  const { 
    loading: loadingHomeProds, 
    error: errorHomeProds, 
    data: homeProds } = useQuery(HOME_PRODUCTS,
      {variables: { userId: mid }},)
  
  if (loadingHomeProds) { return ('Loading...') };
  if (errorHomeProds) { return ('ERROR')};
  
  return {homeProds}}
const EventListTable = (props) => {


  return (
<><Table padded={false} sortable celled selectable>
          <Table.Body>
          {props.eventList ? (props.eventList.findEvent.filter((ev) => (ev.eventtitle!=="START"
           && ev.eventtitle!=="STOP")).map(({_id, eventtitle, voicecmds, compatibleprod, uservariables}, index, array) => (
            <Table.Row key={index} 
            onClick = {async () => {
              props.changeEventSelection(array[index])
              const compats = await reFormComp(array[index].compatibleprod);
              props.setTableDataY(compats.tempComp)
              props.setSaveComplete(true)
              props.setOGEvent({event: array[index], comp: reFormComp(array[index].compatibleprod).tempComp})
              }
              
              }>
        <Table.Cell textAlign='center'>
          {eventtitle} <br/>
          </Table.Cell>
          </Table.Row>
          ))) : <></>}
          </Table.Body>
          <Table.Footer>
          </Table.Footer>
        </Table></>

  )
}

function EventDeetQueries (eventData) {
  const { 
  loading: loadingEventDeets, 
  error: errorEventDeets, 
  data: fullEventDeets, refetch: refetchFullEvents } = useQuery(FULL_EVENT_DEETS,
     {variables: {eventIDs: eventData}},)

  if (loadingEventDeets) { return ('Loading...')};
  if (errorEventDeets) { return ('ERROR')};

  return {fullEventDeets, refetchFullEvents}
  }

const FlowDetails = () => {
const { flowkey } = useParams();


const { user } = useOutletContext();
const { homeProds: loadedHomeProds } = QueryHomeProducts(user.metadata.mid)
const flowID = flowkey;
const [activeFlowSelection, setActiveFlowSelection] = useState(flowID)

const {baseFeatures, loadingBaseFeatures, errorBaseFeatures, refetchBF} = FlowDetailsQuery(flowID, user.metadata.mid)

const [eventList, setEventList] = useState([])

const {fullEventDeets, refetchFullEvents} = EventDeetQueries(eventList)


//function to query event deps for given flow ID
const [activeEvents, setActiveEvents] = useState([])

const { 
  loading: loadingEventDeets, 
  error: errorEventDeets, 
  data: allEventDeets, refetch: refetchEvents } = useQuery(GET_EVENT_DEETS, {variables:{eventIDs: activeEvents}})

  const [eventSelection, setEventSelection] = useState()

  const [recMetaIndex, setRecMetaIndex] = useState()

  const [activeClass, setActiveProductClass] = useState()
  const [sortComboState, setSortComboState] = useState("PRICE")

  const [embedVideo, setEmbedVideo] = useState()
  const [diyStatus, setDIYStatus] = useState(false)


  const {allPreComp, loadingPreComp, errorPreComp, refetchPreComp} = PreCompQuery([activeFlowSelection],user.metadata.mid, sortComboState)

  const {specRows: specProdSet} = ProductSpecQuery(activeClass);

  const changeClassSelection = (classid) => {
    setActiveProductClass(classid)
  }

  const changeEventSelection = (event) => {
    setEventSelection(event)
  }

//seperate base flow and features
let base = []
let features = []
if (baseFeatures) {
  baseFeatures.forEach((a, index) => {
    if (a.feature !== "BASE") {
      features.push(a)
    } else if (a.feature === "BASE") {
      if (baseFeatures.includes("BASE", index+1)) {
        throw console.error("error: multiple base names found");
      } else {
        base = a;
      }
    }
  })
}

//function to extract events unique from deps
const DepsToNodes = (deps) => {
//make deps into distinct events

const curEvents = deps.map((dep) => {
  return dep.eventID._id
})

console.log(allEventDeets)
var uniqueEve = allEventDeets.findEvent.concat([
  {
  __typename: "Event",
  _id: "61d687cc82f38f86797308c8",
  eventtitle: "START",
  actiontrigger: "trigger"
}])

var allEve = []
var allEdg = []
var eveIndex = 1
var nexIndex = 2
deps.forEach(async (dep) => {
  
  allEve.push(dep.eventID)
  //store eventID once as node, and x number of edges (array of nid)
  await dep.nextID.forEach((nid, index) => {
        allEve.push(nid)
        allEdg.push({
      id: `e${eveIndex}-${nexIndex}`,
      source: `${eveIndex}`,
      target: `${nexIndex}`
    })
    nexIndex=nexIndex+1
    if (index==dep.nextID.length-1)
    {
      eveIndex=nexIndex
      nexIndex=nexIndex+1
    }
  })
})


deps.forEach((dep) => {
  dep.nextID.forEach((nid) => {
    var checkStat = 1
    //check if found in uniqueEve
    if (uniqueEve.length==0) {
      uniqueEve.push(nid)
    } else {
      uniqueEve.forEach((un) => {
        if (un._id == nid._id) {
          checkStat = 0
        }
      })
      if (checkStat == 1) {
        uniqueEve.push(nid)
      }
    }
  })
})

//produce nodes and edges
const tempNodes = uniqueEve.map((e, index) => {
  if (e._id == "61d687cc82f38f86797308c8") {
    return {
      id: `${index+1}`,
      type: "input",
      data: {
          label: "START",
          mid: e._id
      },
      position: {
          x: 0,
          y: 0
      },
      style: {
        backgroundColor: Color.rgb(255, 116, 116)
      }
  }
  } else if (e._id == "61c6b4b5003f12350b304b43") {
    return {
      id: `${index+1}`,
      type: "output",
      data: {
          label: "STOP",
          mid: e._id
      },
      position: {
          x: 0,
          y: 0
      },
      style: {
        backgroundColor: Color.rgb(255, 116, 116)
      }
  }}  else if (e.actiontrigger=="trigger") { 
    return {
      id: `${index+1}`,
      type: "default",
      data: {
        label: e.eventtitle,
        mid: e._id
      },
      position: {
          x: 0,
          y: 0
      },
      style: {
        backgroundColor: Color.rgb(31, 218, 53)
      }
    }
   } else {
  return   {
    id: `${index+1}`,
    type: "default",
    data: {
      label: e.eventtitle,
      mid: e._id
    },
    position: {
        x: 0,
        y: 0
    }
}}
})

var tempEdges = []
//for each event dep

deps.forEach((dep) => {
    //for each event ID - next ID
    const curIndex = uniqueEve.findIndex((ue) => (dep.eventID._id == ue._id))
    dep.nextID.forEach((next) => {
        const nexIndex = uniqueEve.findIndex((ue) => (next._id == ue._id))
        
        {tempNodes[curIndex] && tempNodes[nexIndex] &&
        tempEdges.push({
          id: `e${tempNodes[curIndex].id}-${tempNodes[nexIndex].id}`,
          source: `${tempNodes[curIndex].id}`,
          target: `${tempNodes[nexIndex].id}`
        })
        }
        
    }
    )})
//validate unique events against event ID
//for nodes withoutout parrents, make it start

const startNode = tempNodes.filter((n) => (n.data.label=="START"))[0].id
tempNodes.forEach((n) => {

  if (tempEdges.filter((e) => (e.target==n.id)).length == 0) {
    //add edge from start
    tempEdges.push({
      id: `e${startNode}-${n.id}`,
      source: `${startNode}`,
      target: `${n.id}`
    })
  }

})

//any undiscovered events, find eventr and update
setActiveEvents(curEvents)


const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
  tempNodes,
  tempEdges
);

  return {tempNodes, tempEdges}
}

//function to take eventdeps and product nodes and edges
const [nodeState, setNodeState] = useState([])
const [edgeState, setEdgeState]= useState([])


const changeFlowSelection = (flow) => {
  console.log(flow)
  setEmbedVideo(flow.troubleshoot)
  //find unique events
  setOGEvent()
  setActiveFlowSelection(flow.id)
  setActiveProductClass()
  console.log(flow.eventDeps)
  const {tempNodes, tempEdges} = DepsToNodes(flow.eventDeps)
  const eventArr = tempNodes.map((n) => {
    return n.data.mid
  })
  setNodeState(tempNodes)
  setEdgeState(tempEdges)
  setEventList(eventArr)
  refetchFullEvents()
}

const [saveComplete, setSaveComplete] = useState(true)
const [OGEvent, setOGEvent] = useState()
const [tableChange, setTableChange] = useState(1)
const [tableDataY, setTableDataY] = useState([[{text:""}]])



  //open modal for edits (if permissioned TODO:)
const [width, setWidth]   = useState(window.innerWidth);
const [height, setHeight] = useState(window.innerHeight);
const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
}
useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
}, []);



const onLoad = (reactFlowInstance) => {
  reactFlowInstance.fitView();
}

return (
    <Grid>
    <Grid.Row>
              <Grid columns={width<640 ? 1 : 2}>
              <Grid.Column>
              <Grid.Row>
              <Header style={{textAlign:'center', letterSpacing:'1px'}}>SIMILAR FLOWS</Header>
              <Container>
              {base && features && baseFeatures && <BaseFeatureTable 
              width={width} refetch={refetchBF} changeSelection={changeFlowSelection} 
              activeFlow={activeFlowSelection} 
              base={base} features={features} baseFeatures={baseFeatures}/>}
              </Container>
              </Grid.Row></Grid.Column>
              <Grid.Column>
              <Grid.Row style={{textAlign:'center'}}>
              {embedVideo ? <><Header style={{textAlign:'center', letterSpacing:'1px'}}>DEMO / INSTRUCTIONS</Header>
      {activeFlowSelection && <div style={{position: "relative", left:'0px', right:'0px'}}><iframe width="420" height="200" src={embedVideo}
       title="YouTube video player" frameborder="0" allowfullscreen></iframe></div>}</> : <></>}
                  </Grid.Row>
                  <Grid.Row>
                  <Grid.Row>
    <div onClick={()=>{setDIYStatus(!diyStatus)}} className={buttonStyles['btn-grad-diy']}>Do-It-Yourself</div>
    </Grid.Row>
      {diyStatus && activeFlowSelection ? <>

                    <Grid.Row>
      {(loadedHomeProds && activeFlowSelection && allPreComp && allPreComp.flowProducts) ? 
       <ProductTable setRecMetaIndex={setRecMetaIndex} sortComboState={sortComboState} setSortComboState={setSortComboState} homeCL={loadedHomeProds.mongusers[0].homeProducts} productClassData={allPreComp.flowProducts} 
      activeFlow={activeFlowSelection} activeClass={activeClass} changeClassSelection={changeClassSelection}/> :
       ((allPreComp && (allPreComp.flowProducts==null)) ? 
       <><br/><br/><br/><br/><Icon size='big' name='question'/>no products found</> : <><br/><br/><br/><br/><RiseLoader color="#36d7b7" /></>)}
      </Grid.Row>
      <Grid.Row centered>
      {(specProdSet && activeFlowSelection && activeClass) ? <SpecTable recMeta={recMetaIndex.pricedSpecs.filter(spec => spec.class==activeClass)[0]} productSpecData={specProdSet} activeClass={activeClass}/>
       : ((activeFlowSelection && allPreComp) ? ((allPreComp.flowProducts==null) ? <></> : <><br/><br/><br/><br/>
       <div style={{
      textAlign:'center',
            overflowY: 'auto',
            overflowX: 'hidden',
            alignItems: 'right',
             borderRadius: '5px',
             backgroundColor: 'white',
             opacity:'1',
             border: '1px solid lightgray',
             boxShadow: '1px 1px #b8b8b8',
             padding: '20px'

        }}><Icon size="big" name='arrow up'/><br/>
        <a style={{color: 'black', fontWeight:'900'}}>select or hover</a><br/>any product to see purchase options
        </div></>) : <><br/><br/><br/><br/><RiseLoader color="#36d7b7" /></>)}
        </Grid.Row>
      </> : <></>}
                  </Grid.Row>
              </Grid.Column>
              



              </Grid>
              
    </Grid.Row>
    {!activeFlowSelection ? <><Grid.Row centered>
      <Container>
        <Card.Content>
          <div style={{ display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 300,
          fontStyle: "italic"
          }}><Icon name='long arrow alternate up'/>
          select a base or feature to see its chart
          </div></Card.Content>
      </Container></Grid.Row></>:<><Grid.Row centered><h3>CHART</h3><Container>
        <Card.Content><div style={{ height: 400, width: 1000}}>
          <ReactFlow nodes={nodeState} edges={edgeState}
          onInit={onLoad}>
            <Background color='#888' gap={16} />
          <MiniMap nodeColor={n => {
            if(n.type === 'default') return 'teal'
            return '#FFCC00'
          }} />
          <Controls />
          </ReactFlow>
          </div>
        </Card.Content>
      </Container></Grid.Row>
      {(user.id == "374fd833-f742-4183-97a7-f0d135c08e3b") ? <Grid.Row centered>
        <Grid columns={2}>
          <Grid.Column width={4}>
      <Container textAlign='center'>
        <br/>
      <h3>EVENT LIST</h3>
      {fullEventDeets && (eventList.lenght!==0) && <EventListTable setOGEvent={setOGEvent}
           setSaveComplete={setSaveComplete} setTableDataY={setTableDataY}
            changeEventSelection={changeEventSelection} eventList={fullEventDeets}/>}
      </Container></Grid.Column>
      <Grid.Column width={12}><br/><br/>{eventSelection && OGEvent && <CreateUpdateEvent 
      saveComplete={saveComplete} setSaveComplete={setSaveComplete} OGEvent={OGEvent} 
      refetchEvents={refetchFullEvents} tableChange={tableChange} setTableChange={setTableChange}
      setTableData={setTableDataY} tableData={tableDataY} 
      setSelectEvent={setEventSelection} selectEvent={eventSelection}
      eventSelection/>}</Grid.Column></Grid>
    </Grid.Row> : <Grid.Row></Grid.Row>}</>}
    </Grid>
    
    )
}

export default FlowDetails;