import React from 'react'
import { Button, Checkbox } from 'semantic-ui-react'

function CheckboxFlow() {
  const [checked, setChecked] = React.useState(false)

  return (
    <div>
      <Checkbox
        onChange={(e, data) => setChecked(data.checked)}
        checked={checked}
      />
    </div>
  )
}

export default CheckboxFlow
