import React, { useState, useEffect } from 'react';
import { Button, Menu, Icon, Header, Divider, Grid, Container, Dimmer, Loader } from 'semantic-ui-react';
import ReactFlow from 'react-flow-renderer';
import {RiseLoader} from 'react-spinners'
// import styles from '../styles/pages/FlowFinder.module.css';
import 'semantic-ui-css/semantic.min.css';
import CreateProductSpec from '../components/create-product-spec'
import ProductSpecSelector from '../components/product-spec-selector'
import ExpandXYTable from '../components/expand-xy-table'
import MultiDropClasses from '../components/multidrop-classes'
import EventEditor from '../components/event-editor'

import { Link, useOutletContext, Navigate, useNavigate } from 'react-router-dom';

import styles from '../styles/pages/FlowFinder.module.css';
import buttonStyles from '../styles/components/Button.module.css';

import { toast } from 'react-hot-toast'

//import queries
import FlowsQueryFunction from '../components/flows-query-function';
import ProductSpecQuery from '../components/product-spec-query';
import PreCompQuery from '../components/product-class-query-precomp';
import { gql, useQuery, useMutation } from "@apollo/client";

//import tables
import FlowTable from '../components/flow-table';
import ProductTable from '../components/product-table';
import SpecTable from '../components/product-spec-cards';

import TableNav from '../components/table-nav-menu';
import PageTurner from '../components/page-turner';

const HOME_PRODUCTS = gql`
query HomeProducts($userId: String!) {
  mongusers(userID: $userId) {
    homeProducts {
      make
      model
      productClass {
        _id
      }
    }
  }
}
`;

const ADD_FLOW = gql`
mutation addHomeFlow ($addFlow: FlowHome) {
  addHomeFlow (homeFlow: $addFlow) {
    _id
}}
`;

const DELETE_FLOW = gql`
mutation deleteHomeFlow ($delFlow: FlowHome) {
  deleteHomeFlow (homeFlow: $delFlow) {
    _id
}}
`;

const CREATE_MID = gql`
mutation CreateUser ($userInput: UserInput) {
  createUser(userInput: $userInput) {
    _id
  }
}`;

const UPDATE_USER_MUTATION = gql`
  mutation ($id: uuid!, $metadata: jsonb) {
    updateUser(pk_columns: { id: $id }, _set: { metadata: $metadata }) {
      id
      metadata
    }
  }
`

function QueryHomeProducts (mid) {

  const { 
    loading: loadingHomeProds, 
    error: errorHomeProds, 
    data: homeProds } = useQuery(HOME_PRODUCTS,
      {variables: { userId: mid }},)
  
  if (loadingHomeProds) { return ('Loading...') };
  if (errorHomeProds) { return ('ERROR')};
  
  return {homeProds}}
  // //if no mongo ID, create new id and link

const FlowFinder = () => {

  const { user } = useOutletContext();
  let navigate = useNavigate();
      //create mongo user
      const [createMUser, { MUdata, 
        MUloading, MUerror }] = useMutation(CREATE_MID);
  
      //write mongoid to nhost metadata
      const [mutateUser, { loading: updatingProfile }]
       = useMutation(UPDATE_USER_MUTATION)

      const { homeProds: loadedHomeProds } = QueryHomeProducts(user.metadata.mid)

//find classes of home products

  useEffect(() => {

    if (typeof user.metadata.mid == 'undefined')
    {
      try {
      createMUser({ 
        variables: {
        userInput: {
          email: user.email
        }},
        onCompleted: (data) => {
          mutateUser({
            variables: {
              id: user.id,
              metadata: {
                "mid": data.createUser._id
              }
            },
            onCompleted: () => {
              toast.success('Hello new user !', { id: 'newProfile' })

              navigate("/profile");
            }
          })
          },
          onError: (err) => {
          console.log(err)
        }
        })
    } catch (error) {
      toast.error('Unable to update profile', { id: 'newProfile' })
    }} else if ((typeof user.metadata.firstName == 'undefined') || user.metadata.firstName == "")
    {
      navigate("/profile");
    }

    }, [])
  //if no first name, navigate to profile page
  
//state for menu selection
  const [activeMenuItem, setActiveMenuItem] = useState('all')
  
  const [addFlow, {loading: loadingAddFlow, error: errorAddFlow}] = useMutation(ADD_FLOW)

  const [deleteFlow, { loading: loadingDeleteFlow, error: errorDeleteFlow }] = useMutation(DELETE_FLOW)

  //select flow on table and load required products"61c709f100c936e1d96138d6"
  const [activeFlow, setActiveFlow] = useState()    //"set "HOME" if default product class should be recommended based on home list
  
  //state variable for product Class selection
  const [activeClass, setActiveProductClass] = useState()

  const [recMetaIndex, setRecMetaIndex] = useState()

  const [stateFlows, setStateFlows] = useState([])

  const [sortComboState, setSortComboState] = useState("PRICE")

const {flowsLoaded: flowsLoaded, loadingAllFlows, errorAllFlows, refetch: refetchFlows} = FlowsQueryFunction("all", 0, 32, user.metadata.mid);

const {allPreComp, loadingPreComp, errorPreComp, refetchPreComp} = PreCompQuery([activeFlow], user.metadata.mid, sortComboState)

//----------------------------------------
//save wish status and flow details as state
//display state
//update state, and run mutation concurrently
//update flow data
//show loading indicator new flow updating
//if mutation is not successful, show warning near flow




  const changeSelection = async (id) => {
    setActiveFlow(id);
    setActiveProductClass({})
    {refetchPreComp && 
      await refetchPreComp({flowID: id})}
  }

  const {specRows: specProdSet, loadingSpecs, errorSpecs, refetchSpecs} = ProductSpecQuery(activeClass);

  const changeClassSelection = (classid) => {
    setActiveProductClass(classid)
  }

  const ADD_PRODUCT = gql`
  mutation AddProduct($inputSpec: SpecInput) {
      addProductInfo(inputSpec: $inputSpec) {
        _id
      }
    }
`;

  const NewRequestButtons = () => {
    //query to find dropdown product specs
const GET_SPECS = gql`
query listSpec ($classID: String!) {
  specProducts(productClassID: $classID) 
  {
  _id
  make
  model
  productClass{
    _id
    className
  }
  }
}
`;
function QuerySpecProducts (classID) {
  const { 
    loading: loadingSpecs, 
    error: errorSpecs, 
    data: allSpecs } = useQuery(GET_SPECS,
      {variables: { classID }},)
  
  if (loadingSpecs) { return ('Loading...') };
  if (errorSpecs) { return ('ERROR')};
  
  return {allSpecs}}

//query to find dropdown product specs
const GET_CLASSES = gql`
  query allClasses {
    products  {
      _id
      className
    }
  }
`;


//query for classes
function QueryClassProducts () {
const { 
  loading: loadingClasses, 
  error: errorClasses, 
  data: allClasses } = useQuery(GET_CLASSES)

if (loadingClasses) { return ('Loading...') };
if (errorClasses) { return ('ERROR')};

return ({allClasses, loadingClasses, errorClasses}
  )}



  const GET_EVENT_DEETS = gql`
  query listProductClasses ($eventIDs: [String!]) {
    findEvent(eventID:$eventIDs){
          _id
          eventtitle
          actiontrigger
          compatibleprod{
            _id 
          }
          uservariables
          voicecmds{
            _id
            command
          }
    }
  }
`;
  
  function EventDeetQueries (eventData) {
    const { 
    loading: loadingEventDeets, 
    error: errorEventDeets, 
    data: allEventDeets, refetch: refetchEvents } = useQuery(GET_EVENT_DEETS,
       {variables: {eventIDs: eventData}},)

    if (loadingEventDeets) { return ('Loading...')};
    if (errorEventDeets) { return ('ERROR')};

    return {allEventDeets, refetchEvents}
    }

    const {allEventDeets, refetchEvents} = EventDeetQueries(("ALL"))
    const [eventList, setEventList] = useState([])






if (user.id == "374fd833-f742-4183-97a7-f0d135c08e3b")
{
    return (
          <><CreateProductSpec mode={"NEW"} gq={ADD_PRODUCT}/>
          <ProductSpecSelector />
          {allEventDeets && <EventEditor refetchEvents={refetchEvents} setEventList={setEventList} eventList={eventList}
          allEventDeets={allEventDeets}/>}<br /><br />
          <br />
          {/* <ExpandXYTable allSpecs={allSpecs} tableData={tableDataXY} setTableData={setTableDataXY}/> */}
          {/* {allClasses.allClasses && <MultiDropClasses xIndex={0} yIndex={0} allClasses={allClasses.allClasses.products} tableData={tableDataClasses} setTableData={setTableDataClasses}/>} */}
</>
    )} else {
      return ( <></>)
    }
  }
  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
  }
  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const [embedVideo, setEmbedVideo] = useState()

  const [flowFilterMenu, setFlowFilterMenu] = useState()

  const handleMobileMenuItemClick = (e, { value }) => {
            switch (value)
            {
              case "energy":
                setActiveMenuItem(value);
                refetchFlows({
                  functionTag: 'energy'
                })
                break;
              case "security":
                setActiveMenuItem(value)
                refetchFlows({
                  functionTag: 'security'
                })
                break;
              case "comfort":
                setActiveMenuItem(value)
                refetchFlows({
                  functionTag: 'home comfort'
                })
                break;
              case "habits":
                setActiveMenuItem(value)
                refetchFlows({
                  functionTag: 'daily tasks'
                })
                break;
                case "other":
                  setActiveMenuItem(value)
                  refetchFlows({
                    functionTag: 'untagged'
                  })
                  break;
              default:
                setActiveMenuItem(value)
                refetchFlows({
                  functionTag: value
                })
            }
        }
        
        const handleMenuItemClick = (e, { name }) => {

const value = name
                  switch (value)
                  {
                    case "energy":
                      setActiveMenuItem(value);
                      refetchFlows({
                        functionTag: 'energy'
                      })
                      break;
                    case "safety":
                      setActiveMenuItem(value)
                      refetchFlows({
                        functionTag: 'safety-security'
                      })
                      break;
                    case "comfort":
                      setActiveMenuItem(value)
                      refetchFlows({
                        functionTag: 'home comfort'
                      })
                      break;
                    case "habits":
                      setActiveMenuItem(value)
                      refetchFlows({
                        functionTag: 'daily tasks'
                      })
                      break;
                      case "other":
                        setActiveMenuItem(value)
                        refetchFlows({
                          functionTag: 'untagged'
                        })
                        break;
                    default:
                      setActiveMenuItem(value)
                      refetchFlows({
                        functionTag: value
                      })
                  }
              }
    return (
      <><div>
      <Grid columns={width<640 ? 1 : 2}>
      <Grid.Row textAlign = "center">
      <Grid.Column width={width<640 ? null : 9}>
        <>
        <TableNav activeItem={activeMenuItem} handleMobileMenu={handleMobileMenuItemClick} handleItemClick={handleMenuItemClick}/>
          {(!flowsLoaded) ? <Dimmer active inverted><Loader /></Dimmer> 
          : 
          <>
            <FlowTable setEmbedVideo={setEmbedVideo} width={width} mongoID={user.metadata.mid} wishlist={false} loadingHome={loadingPreComp}
            refetchHomeFlows={refetchPreComp} refetchFlows={refetchFlows}
            flowsLoaded={flowsLoaded}
            stateFlows={stateFlows} setStateFlows={setStateFlows}
            delFlow={deleteFlow} addFlow={addFlow}
            flowData={flowsLoaded}
            activeFlow={activeFlow} changeSelection={changeSelection}/>
            
        {(width<466) ? <></> : <div>          <Divider/>
<div style={{overflowX:'auto'}}>
          <Menu size={(width<1024) ? ((width<640) ? 'large' : 'mini') : 'large'} secondary>
        <Menu.Item
          name='all'
          active={activeMenuItem === 'all'}
          onClick={handleMenuItemClick}
        />
        <Menu.Item
          icon='plus circle'
          name='safety'
          active={activeMenuItem === 'safety & security'}
          onClick={handleMenuItemClick}
        />
        <Menu.Item
          icon='battery half'
          name='energy'
          active={activeMenuItem === 'energy'}
          onClick={handleMenuItemClick}
        />
        <Menu.Item
          icon='tv'
          name='entertainment'
          active={activeMenuItem === 'entertainment'}
          onClick={handleMenuItemClick}
        />
        <Menu.Item
          name='other'
          active={activeMenuItem === 'other'}
          onClick={handleMenuItemClick}
        />
      </Menu></div></div>}
      </>}</>
            
      </Grid.Column>
      <Grid.Column width={width<640 ? null : 7}>
        
    <div>
    <div style={{marginLeft:'30px'}}>
    {(embedVideo && width>640) ? <Grid.Column><Header style={{letterSpacing:'1px'}}>DEMO</Header>
      {activeFlow && <div style={{position:"relative",bottom: 0,right: 0}}><iframe width="420" height="200" src={embedVideo+";controls=1&amp&amp;showinfo=0&amp;modestbranding=1"}
       title="YouTube video player" frameborder="0" allowfullscreen></iframe></div>}</Grid.Column> : <></>}</div>
      <br/><Grid.Column>
      <div style={{marginLeft:'30px'}}><Divider/></div>
      {(activeFlow ? <></> : <div>
        <Grid.Row style={{marginLeft:'30px'}}>
        <div style={{textAlign:'left', fontSize:'18px'}}><a style={{color:'black', fontWeight:'900'}}>Find</a><a style={{color:'black', fontSize:'18px', fontWeight:'100'}}>
          <br/>filter and select (<Icon name={width<640 ? 'arrow up' : 'arrow left'}/>) a home automation flow to find demos and compatible devices</a><br/><br/>
        <a style={{color:'black', fontWeight:'900'}}>Like</a><a style={{color:'black', fontSize:'18px', fontWeight:'100'}}><br/>give <Icon name='heart'/>'s to any flows you want</a><br/>
        <br/><a style={{color:'black', fontWeight:'900'}}>Build</a><a style={{color:'black', fontSize:'18px', fontWeight:'100'}}><br/>get personalized recommendations and build options in your wishlist</a>
        </div>
        <br/>
        <br/><br /></Grid.Row></div>)}</Grid.Column>

      <Grid.Row>
      {(!activeFlow) ? (<NewRequestButtons />) : (<Grid columns={3}>
      <Grid.Row style={{marginLeft:'30px'}}>
      {(loadedHomeProds && activeFlow && allPreComp && allPreComp.flowProducts) ?
       <ProductTable setRecMetaIndex={setRecMetaIndex} sortComboState={sortComboState} setSortComboState={setSortComboState} homeCL={loadedHomeProds.mongusers[0].homeProducts} productClassData={allPreComp.flowProducts} 
      activeFlow={activeFlow} activeClass={activeClass} changeClassSelection={changeClassSelection}/> :
       ((allPreComp && (allPreComp.flowProducts==null)) ? 
       <><br/><br/><br/><br/><Icon size='big' name='question'/>no products found</> : <><br/><br/><br/><br/><RiseLoader color="#36d7b7" /></>)}
      </Grid.Row>
      <Grid.Row style={{marginLeft:'30px'}} centered>
      {(specProdSet && activeFlow && activeClass) ? <SpecTable recMeta={recMetaIndex.pricedSpecs.filter(spec => spec.class==activeClass)[0]} productSpecData={specProdSet} activeClass={activeClass}/>
       : ((activeFlow && allPreComp) ? ((allPreComp.flowProducts==null) ? <></> : <><br/><br/><br/><br/>
       <div style={{
            overflowY: 'auto',
            overflowX: 'hidden',
            alignItems: 'right',
             borderRadius: '5px',
             backgroundColor: 'white',
             opacity:'1',
             border: '1px solid lightgray',
             boxShadow: '1px 1px #b8b8b8',
             padding: '20px'

        }}><Icon size="big" name='arrow up'/><br/>
        <a style={{color: 'black', fontWeight:'900'}}>select or hover</a><br/>any product to see purchase options
        </div></>) : <><br/><br/><br/><br/><RiseLoader color="#36d7b7" /></>)}
      </Grid.Row>
      </Grid>)}
      </Grid.Row></div>
      </Grid.Column>
      </Grid.Row>
      </Grid></div></>
      )
    }

export default FlowFinder;
