import React, {useState} from 'react'
import { Button, Modal, Image, Form, Header } from 'semantic-ui-react'

import styles from '../styles/components/AddProductCard.module.css';
import buttonStyles from '../styles/components/Button.module.css';

import { toast } from 'react-hot-toast';
import ReactGA from "react-ga";


const ServiceForm = (props) => {




    const [comments, setComments] = useState();
    const [serviceLocation, setServiceLocation] = useState();
    // const [surveyResponse, setSurveyResponse] = useState(props.srInput.surveyResponse);

    const [open, setOpen] = useState(false)

    const initVars = () => {
        setComments();
        setServiceLocation()
    }

    const handleSR = () => {
    ReactGA.event({
            category:"SR FORM",
            action:"SUBMIT REQUEST",
            label:`mid:${props.mid}`
          })
    try {
        props.submitSR({variables: {
            srInput: {
                comments: comments,
                mid: props.mid,
                serviceLocation: serviceLocation
            }
        }}).then(() => {
            setOpen(false)
            toast.success('Service request submitted! Please submit any questions to support@nuhome.solutions', { id: 'submitSeviceRequest' })

        })
    } catch (err) {
        toast.error('Error: please email support@nuhome.solutions', { id: 'submitSeviceRequest' })
    }


    }

return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => {ReactGA.event({
            category:"SR FORM",
            action:"MODAL OPEN",
            label:`mid:${props.mid}`
          });initVars();setOpen(true)}}
        open={open}
        trigger={<div className={buttonStyles['btn-grad-wishlist']}>Request Quote</div>}
        ><Header style={{letterSpacing:'1px', textAlign:'center'}}>We offer service in Vancouver and Calgary 🇨🇦</Header><br/>
        <Modal.Header>Request an installation quote<br/><div style={{fontWeight:'200', fontSize:'18px'}}>Device or hardware costs are estimated <a></a>${Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(props.srPriceMin)} to ${Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(props.srPriceMax)}</div></Modal.Header>
        <Modal.Content image>
        <>

            <div className={styles['image-crop-logo']}><Image bordered src={process.env.PUBLIC_URL + 'logoHD.png'}/></div></>
            <Form>
            <Form.Group widths='equal'>
            <Form.Input
            value={serviceLocation}
            label='Location of smart home'
            placeholder='City/State/Country'
            onChange={(e) => setServiceLocation(e.target.value)}
            />
            <Form.Input
            style={{height:'300px', width: ' 300px'}}
            value={comments}
            label={'Other Comments'}
            placeholder={'Include any extra details.'}
            onChange={(e) => setComments(e.target.value)}
            />
            </Form.Group>
        </Form>
        </Modal.Content>
        
        <div style={{letterSpacing:'1px', padding: '50px', textAlign:'center'}}>

            Depending on your location, requested flows and home devices, we will respond with a quote from our trusted service partner</div>
        <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Cancel
        </Button>

        <Button
        style={{
            backgroundImage: 'linear-gradient(to top, #09203f 0%, #537895 100%)'
        }}
          content="Submit"
          labelPosition='right'
          icon='checkmark'
          onClick={() => {handleSR()}}
          positive
        />
      </Modal.Actions>
      </Modal>
    )

}

export default ServiceForm