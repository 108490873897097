import React, { useState } from 'react'
import { Modal, Button, Icon, Dimmer, Grid, Container, Table, Pagination, Loader } from 'semantic-ui-react'

import { gql, useQuery, useMutation } from "@apollo/client";

import CreateProductSpec from "../components/create-product-spec"

//Table of product specs
//use header as trigger for modal (edit)
//query to find dropdown product specs
const GET_ALL_SPECS = gql`
  query listSpec ($classID: String!) {
    specProducts(productClassID: $classID) 
    {
    _id
    make
    model
    price
    classUnits
    currency
    urlPurchase
    photoURL
    productClass{
      _id
      className
    }
    }
  }
`;

const UPDATE_PRODUCT = gql`
        mutation UpdateProductInfo($specUpdate: SpecUpdate) {
          updateProductInfo(specUpdate: $specUpdate) {
            _id
            }
          }
    `;

    const ADD_PRODUCT = gql`
    mutation AddProduct($inputSpec: SpecInput) {
        addProductInfo(inputSpec: $inputSpec) {
          _id
        }
      }
  `;

function QuerySpecProducts (classID) {
    const { 
      loading: loadingSpecs, 
      error: errorSpecs, 
      data: allSpecs, refetch: reSpecs } = useQuery(GET_ALL_SPECS,
        {variables: { classID }},)
    
    if (loadingSpecs) { return ('Loading...') };
    if (errorSpecs) { return ('ERROR')};
    
    return ({allSpecs, loadingSpecs, errorSpecs, reSpecs}
      )}
    

      //CREATE COMPONENT FOR UPDATE FORMS


const SpecSelector = (props) => {

    const [activeSpec, setActiveSpec] = useState()

    const [open, setOpen] = useState(false)

    const {allSpecs, reSpecs} = QuerySpecProducts("ALL")


    const holdSelected = (id) => {
        if (id === activeSpec) {
          return true
        } else {return false}
      }


    return (<>
    <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button secondary>Update Product</Button>}
      >
        <Modal.Header>
        <Grid><Grid.Row columns={2}>
          <Grid.Column>
          🔍  Find and edit products</Grid.Column>
          <Grid.Column textAlign='right'><Button onClick={() => 
            {reSpecs({ classID:"ALL" })}} icon>
    <Icon name='refresh' />
  </Button></Grid.Column></Grid.Row></Grid>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
  <Table padded={false} sortable celled selectable>
      <Table.Body>
        {(!allSpecs || (allSpecs == 'Loading...') ||
    (allSpecs == 'ERROR')) ? (<Dimmer active inverted><Loader /></Dimmer>)
          : (allSpecs.specProducts.map(({ _id, make, model, price, urlPurchase, photoURL, currency, classUnits, productClass}, index) => (
          <Table.Row key={index} onClick = {() => {setActiveSpec(_id)}}>
            <Table.Cell textAlign='center'>
              {make} <br/>
              <CreateProductSpec refresh={reSpecs} gq={UPDATE_PRODUCT} mode={"UPDATE"}
              updateModel={model} updateMake={make} updatePrice={price}
              updateURL={urlPurchase} updateCurrency={currency} updateUnits={classUnits}
              productClass={productClass} photoURL={photoURL} specID={_id}/>
            </Table.Cell>
          </Table.Row>
        )))}
      </Table.Body>
      <Table.Footer>
      <Table.Row>
      </Table.Row>
      </Table.Footer>
    </Table>
    </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Grid>
        <Grid.Row columns={2}>
        <Grid.Column textAlign='left'>
          <CreateProductSpec mode={"NEW"} gq={ADD_PRODUCT}/></Grid.Column>
          <Grid.Column><Button
          content="DONE"
          labelPosition='right'
          icon='checkmark'
          onClick={() => {}}
          positive/></Grid.Column></Grid.Row></Grid>

      </Modal.Actions>
    </Modal>
    </>)
}

export default SpecSelector