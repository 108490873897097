import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { resolvePath } from 'react-router-dom';


const GET_PRODUCT_CLASSES = gql`
  query listProductClasses ($flowID: [String!], $mID: String, $sorter: String) {
    flowProducts(productFlowID:$flowID, mID:$mID, sorter: $sorter){
      first {
        _id
        className
      }
      first_meta {
        opti
        missPriceClass
        noHomeOpti
        totalPrice
        pricedSpecs {
          price
          class
          spec {
            _id
            make
            model
            urlPurchase
            price
            currency
            classUnits
          }
        }
      }
      second {
        className
        _id
      }
      second_meta {
        missPriceClass
        noHomeOpti
        opti
        totalPrice
        pricedSpecs {
          price
          class
          spec {
            _id
            make
            model
            urlPurchase
            price
            currency
            classUnits
          }
        }
      }
      third {
        className
        _id
      }
      third_meta {
        missPriceClass
        opti
        noHomeOpti
        totalPrice
        pricedSpecs {
          price
          class
          spec {
            _id
            make
            model
            urlPurchase
            price
            currency
            classUnits
          }
        }
      }
      homeFlows {
        _id
        feature
        basename
      }
      homeProducts {
        _id
        make
        model
      }
      flows {
        _id
        basename
        feature
        ratings{
          degree
          criteria
        }
        description
      }
    }
  }
`;

function PreCompProductClassQuery(flowID, mID, sorter) {
  const { 
    loading: loadingPreComp, 
    error: errorPreComp, 
    data: allPreComp,
    refetch: refetchPreComp } = useQuery(GET_PRODUCT_CLASSES,
      {variables:{flowID: flowID, mID: mID, sorter: sorter }})


  return (
        {allPreComp, loadingPreComp, errorPreComp, refetchPreComp}
      )
    }
      
  
export default PreCompProductClassQuery
