import React from 'react';
import ReactFlow, { Background, Controls, MiniMap,  } from 'react-flow-renderer';

const onLoad = (reactFlowInstance) => {
  reactFlowInstance.fitView();
}

const FlowGraph = (props) => {
//v10 upgrades
//take elements and split into nodes and edges based on ID

  return(
      <>
      <ReactFlow 
        onEdgesChange={props.onEdgesChange}
        onNodesChange={props.onNodesChange}
        nodes={props.nodeProps}
        edges={props.edgeProps}
        onInit={onLoad} 
        onConnect={props.onConnectProps}
        style={props.styleProps}
        connectionLineStyle={{stroke: '#ddd', strokeWidth: 2}}
        connectionLineType='bezier'
        snapToGrid={true}
        snapGrid={[16,16]}>
          <Background color='#888' gap={16} />
          <MiniMap nodeColor={n => {
            if(n.type === 'default') return 'teal'
            return '#FFCC00'
          }} />
          <Controls />
      </ReactFlow>
      </>
  )
}

export default FlowGraph;