import React, { useState } from 'react';
import { Grid, Button, Card, Icon, Image } from 'semantic-ui-react'
import FlowRatings from './flow-ratings'
import { Link } from 'react-router-dom';
import { gql, useQuery, useMutation } from "@apollo/client";

import FlowCheckbox from './flow-checkbox'

import styles from '../styles/components/FlowCard.module.css';


const flowCard = (props) => {  
  // need to invert flowCount when heart button is pushed

  const Buttons = () => {

    return (
  <Button.Group icon vertical>
  <Button onClick={() => {
    (props.wishStat=="yes") ? props.delFlow({flowID: props.id, mongoID: props.mongoID})
   : props.addFlow({flowID: props.id, mongoID: props.mongoID})}} circular icon>{props.wishStat=="yes" ? <Icon name='heart'/>
    : <Icon name='heart outline'/>}</Button>
  {(props.width>=640) ? <Button><Link to={'/details'}
      style={{ color: 'black' }}
      state={{ flow: props.id }}>customize</Link></Button>:<></>}</Button.Group>)}

      const mobileWidth = () => {
        if (props.width < 640) {
          return true
        } else return false
      }
      
    //const [photoURL, setPhotoURL] = useState(`https://raw.githubusercontent.com/sarulnat/public-assets/main/flow-all.png`)
    var photoURL = `https://raw.githubusercontent.com/sarulnat/public-assets/main/${props.id}.png`

      // fetch(`https://raw.githubusercontent.com/sarulnat/public-assets/main/${props.id}.png`).then((response) => {
      //   console.log(response.status)
      //  if (response.status == 200) {
        
      //    photoURL = `https://raw.githubusercontent.com/sarulnat/public-assets/main/${props.id}.png`
      //    console.log(photoURL)
      //    //resolve(photoURL)
   
      //  } else if (response.status === 404) {
      //    console.log("ERROR")
      //    photoNotFound = 404
      //    photoURL = `https://raw.githubusercontent.com/sarulnat/public-assets/main/flow-all.png`
      //    console.log(photoURL)
      //    //resolve(`https://raw.githubusercontent.com/sarulnat/public-assets/main/flow-all.png`)
      //  }


       //})

       var photoDefault = `https://raw.githubusercontent.com/sarulnat/public-assets/main/flow-all.png`
       
return (
<>
  {/* {props.wishlist ? <Grid.Column style={{width: '5%'}}><FlowCheckbox /></Grid.Column>:<div></div>} */}
    <Card 
    key={props.id}
    onClick = {() => {props.changeSelection(props.id);props.setEmbedVideo(props.troubleshoot)}}
    style = {{backgroundImage: props.holdSelected(props.id) ? "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)" : "none"}}
    centered fluid>{photoURL ? <img style={{objectFit: 'cover',maxHeight:'140px'}} bordered src={photoURL} wrapped ui={false}/> : <></>}
    <div style={{position:'relative', top:'-100px'}}></div><Card.Content>
    <Card.Header>
        <div style={{fontWeight: props.holdSelected(props.id) ? '900' : '400'}} className={styles['card-header']}>{props.basename}
      <></>{(props.feature=="BASE") ? (<></>) : (<> / {props.feature}</>)}</div></Card.Header>
      <br/><Card.Description>{props.ratings && <FlowRatings ratingsArray={props.ratings}/>}</Card.Description>
      <div style={{color:'black'}}>{props.description}<br /><a style={{fontSize: "10px", color:"black"}}>

      </a></div>
      <br/>
        <div>
        <Button size={props.width<640 ? ('tiny') : ('medium')} basic><Link to={'/details/'.concat(props.id)}
      style={{ color: 'black' }}
      state={{ flow: props.id }}>details</Link></Button>
        <Button size={props.width<640 ? ('tiny') : ('medium')} basic onClick={() => {
    (props.wishStat=="yes") ? props.delFlow({flowID: props.id, mongoID: props.mongoID, flowIndex:props.flowIndex})
   : props.addFlow({flowID: props.id, mongoID: props.mongoID, flowIndex:props.flowIndex})}} circular icon>{props.wishStat=="yes" ? <Icon name='heart'/>
    : <Icon name='heart outline'/>}</Button>
        </div>

      </Card.Content>

    </Card>
    </>
)}

export default flowCard