import { useState } from "react";
import { getOutgoers } from "react-flow-renderer";
import { Button, Form, Header, Icon, Modal} from "semantic-ui-react";
import { gql, useMutation } from "@apollo/client";

import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-hot-toast'


const SUBMIT_FLOW = gql`
  mutation SubmitFlow($flowInput: FlowInput) {
    createFlow(flowInput: $flowInput) {
      _id
    }
  }
`;

const SUBMIT_EVENT_DEPS = gql`
  mutation SubmitEventDeps($eventdep: AddeventDep) {
    createEventdeps(eventdep: $eventdep) {
      _id
    }
  }
`;

export default function FlowInputs(props){
  const { user } = useOutletContext();

const mongoID = user.metadata.mid
  const [feature, setFeature] = useState('');
  const [basename, setBasename] = useState('');
  const [status, setStatus] = useState('');
  const [description, setDescription] = useState('');
  const [roomTags, setRoomTags] = useState([]);
  const [functionTags, setFunctionTags] = useState([]);
  const [open, setOpen] = useState(false);

  const [createFlow, { loading: submissionLoad, error: submissionError}] = useMutation(SUBMIT_FLOW)
  const [createEventDeps, { loading: eventSubmissionLoad, error: eventSubmissionError}] = useMutation(SUBMIT_EVENT_DEPS)
  const handleSubmit = async (e) => {
    try {

    let newEventDeps = []
    // get event id and outgoer ids of all events in the flow and add them to the eventDeps array
    let flowDeps = [];
    props.flowProps.forEach(node => {
      if (!node.source) {
        flowDeps.push({ event: node, nextID: getOutgoers(node, props.nodes, props.edges)});
      }
    });
    // Replace the id for each event and outgoers in the flowDeps array with id from eventArray props by matching the eventName with node data label
    flowDeps.forEach(dep => {
      if (dep.event.type !== 'input') {
      dep.event.id = props.eventArrayProps.find(event => event.eventName === dep.event.data.label).id;
      }
      dep.nextID.forEach(outgoer => {
        if (outgoer) {
          outgoer.id = props.eventArrayProps.find(event => event.eventName === outgoer.data.label).id;
        }
      });
    });
    // set event and next id array for each event in the flowDeps array to the eventDeps array
    let eventDeps = [];
    flowDeps.forEach(dep => {
      eventDeps.push({ event: dep.event.id, nextID: dep.nextID.map(outgoer => outgoer.id)});
    });
    // set first event id to '61c6b4b5003f12350b304b43' and if nextID arrary = 0 to '61d687cc82f38f86797308c8'
    eventDeps.forEach(dep => {
      if (dep.nextID.length === 0) {
        dep.nextID.push('61d687cc82f38f86797308c8');
      }
    });
    eventDeps.forEach(dep => {
      if (dep.event === '1') {
        dep.event = '61c6b4b5003f12350b304b43';
      }
    });

    // submit event deps array using async await
    for (let i = 0; i < eventDeps.length; i++) {
      await createEventDeps({ variables: { 
        eventdep: { 
          eventID: eventDeps[i].event,
          nextID: eventDeps[i].nextID
        }} 
      }).then(res => {
      newEventDeps.push(res.data.createEventdeps._id);
      })
    }
    if (eventDeps && feature && basename && status) {
      const revRT = () => {
        if (roomTags) {
          return roomTags
        } else {
          return ""
        }
      }
      const revFT = () => {
        if (functionTags) {
          return functionTags
        } else {
          return ""
        }
      }
      //console.log(newEventDeps)
      await createFlow({
        variables: {
          flowInput: {
            eventDeps: newEventDeps,
            basename: basename,
            feature: feature,
            status: status,
            description: description,
            roomtags: revRT,
            functiontags: revFT,
            mid: mongoID
          }
        }
      })  
    }
    setOpen(false);
    toast.success('Flow created!', { id: 'newFlow' })
    // props.setFlowProps([]);
    props.setNodes([])
    props.setEdges([])
    props.setEventArrayProps([]);

    } catch (error) {
      console.log(error)
    }
  }

  if (submissionLoad) return 'Submitting...';
  if (submissionError) return `Error! ${submissionError.message}`;
  if (eventSubmissionLoad) return 'Submitting...';
  if (eventSubmissionError) return `Error! ${eventSubmissionError.message}`;

  return (
    <>
    <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}
      trigger={
        <Button basic color='teal' animated='vertical'>
        <Button.Content visible>Submit</Button.Content>
        <Button.Content hidden>
          <Icon name='arrow right' />
        </Button.Content>
      </Button>
    }>
      <Modal.Header>Submit Flow</Modal.Header>
    <Modal.Content>
      <Header as='h3' icon='settings' content='Flow Details' />
      <Form>
        <Form.Group widths='equal'>
        <Form.Input
          label='Basename'
          placeholder='Basename'
          onChange={(e) => setBasename(e.target.value)}
        />
        <Form.Input
          label='Feature'
          placeholder='Feature'
          onChange={(e) => setFeature(e.target.value)}
        />
        <Form.Input
          label='Status (serviced/proposed)'
          placeholder='Status'
          onChange={(e) => setStatus(e.target.value)}
        />
        </Form.Group>
        <Form.TextArea
          label='Description'
          placeholder='Description'
          onChange={(e) => setDescription(e.target.value)}
        />
        <Form.Group widths='equal'>
        <Form.Input
          label='Room Tags'
          placeholder='Room Tags'
          onChange={(e) => setRoomTags(e.target.value)}
        />
        <Form.Input
          label='Function Tags'
          placeholder='Function Tags'
          onChange={(e) => setFunctionTags(e.target.value)}
        />
        </Form.Group>
      </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type='submit' color="green" onClick={handleSubmit}>Submit</Button>
      </Modal.Actions>
    </Modal>
  </>
  )
}