import { relayStylePagination } from '@apollo/client/utilities'
import React from 'react'
import { Card, Grid, Image, GridRow, GridColumn, Icon } from 'semantic-ui-react'

import styles from '../styles/components/AddProductCard.module.css';
import buttonStyles from '../styles/components/Button.module.css';
import {SpecPhotoPopup, SpecTitle} from './spec-product-popup'

import ReactGA from "react-ga";

const productCard = (props) => {

  const handleDelete = async () => {
    
    await props.deleteProduct({ variables: {
      delProductSpec: {
        prodSpecID: props.id,
        assignedUser: props.mongoID
      }}});
      // props.refreshHomeProds()
      props.refetchHP({ userID: props.mongoID })
    }

  if (props.prodsLoaded) {
  // console.log(props.prodsLoaded);
  var prodCount = 0
  for( var i = 0; i < props.prodsLoaded.length; i++){ 
    if ( props.prodsLoaded[i].id.toString() === props.id) { 
        prodCount = prodCount+1;
    }
  }}

const photoURL = (props.photoURL ? props.photoURL : `https://raw.githubusercontent.com/sarulnat/public-assets/main/${props.classID}.png`)
console.log(photoURL)

//`https://raw.githubusercontent.com/sarulnat/public-assets/main/${props.classID}.png`


var recStat
if (!props.home && props.recommend?.spec._id==props.id) {
  recStat = true
} else {
  recStat = false
}
  return(
  <>
    <Card onClick={() => {ReactGA.event({
        category:"SPEC PRODUCT",
        action:"CARD CLICK",
        label:`specID:${props.id};mid:${props.mongoID};url:${props.urlPurchase}`
      })}
    } image href={props.urlPurchase} target="_blank" rel="noopener noreferrer" style={{
    maxHeight:(props.home==true ? "25em" : 'null'),
    maxWidth:'20em',
    backgroundImage: (recStat ? 'linear-gradient(to top, #3b41c5 0%, #a981bb 49%, #ffc8a9 100%)' : 'none')
    }}>
    {(props.home==true) ? (
      <>{photoURL ? <><br/><img style={{marginRight:'20px',marginLeft:'20px',objectFit: 'cover',maxHeight:'140px',borderRadius:'10px'}} bordered src={photoURL} wrapped ui={false}/></> : <></>}
      <div style={{width:'10em', transform: 'translate(50%, 0%)'}}><br/></div></>) : (<></>)}
    <Card.Content>
      <Card.Header style={{color: "black", overflowY:'auto', maxHeight:'10em'}}>{(props.home==true) ? (props.nickName ? <><SpecTitle nickName={props.nickName}/></> : <>{props.model} / {props.make}</>) : <SpecPhotoPopup nickName={props.nickName} make={props.make} model={props.model} photoURL={props.photoURL}/>}</Card.Header>
{prodCount ? <Card.Description>
  <Grid.Row><Grid.Column style={{color: 'black', width:'auto'}} floated='left'></Grid.Column></Grid.Row>
      </Card.Description> :
      <Card.Description><Grid.Column textAlign='left'>By: {props.make}</Grid.Column></Card.Description>
      }
      {props.home==false ?
      <a style={{
        color: recStat ? 'white' : 'black',
        fontWeight: recStat ? '600' : 'black'}}>
      {(recStat && !props.single ? <div style={{fontWeight:'600'}}>best price<br/></div> : <></>)}

        <Icon name='shop' />
        {(new URL(props.urlPurchase)).hostname.replace('www.','')}<br/>

        ${Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(props.price)}
      </a>
    :
      <GridRow>

      <GridColumn floated="left"><a onClick={() => {handleDelete()}}
      href={props.urlPurchase} target="_blank" 
      textAlign='right' rel="noopener noreferrer"
      style={{color: "black", fontSize: 12 }}>
        <Icon name='delete' />
        REMOVE
      </a></GridColumn>
      <GridColumn floated="right">
      <a style={{color: "black", fontWeight: "bolder", fontSize: 12 }} textAlign='right'>
      QTY ({prodCount})
      </a></GridColumn></GridRow>
    }</Card.Content>
  </Card></>
)}

export default productCard



//adding multiple same products
//quantity idnicator on product card
//deleting last product