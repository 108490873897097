import { React, useState, useEffect } from 'react'
import { Modal, Button, Icon, Grid, Container, Table } from 'semantic-ui-react'

import CreateUpdateEvent from '../components/update-event'




const reFormComp = (comp) => {
  var tempComp = [[]]
  comp.forEach(async (xProd,xIndex) => {
    var tempY = []
    await xProd.forEach((yProd, yIndex) => {

      tempY.push(yProd._id)
    })
    tempComp[0].push({text: tempY})
  })
  return {tempComp}
}

    

//component for editing/updating events (right pane)


const EventEditor = (props) => {

  const [tableDataY, setTableDataY] = useState([[{text:""}]])
  const [tableChange, setTableChange] = useState(1)
    const [open, setOpen] = useState(false)

    const [selectEvent, setSelectEvent] = useState()
    // const [resultEvents, setResultEvents] = useState()

    const [OGEvent, setOGEvent] = useState()

    const [saveComplete, setSaveComplete] = useState(true)


props.setEventList(props.allEventDeets?.findEvent)

    return (<>
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button secondary>Event Editor</Button>}
          >
            <Modal.Header>
            <Grid><Grid.Row columns={2}>
              <Grid.Column>
              🔍  Find and edit events</Grid.Column>
              <Grid.Column textAlign='right'>
                <Button onClick={() => 
                {props.refetchEvents()}} icon>
        <Icon name='refresh' />
      </Button></Grid.Column></Grid.Row></Grid>
            </Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <Grid.Row columns={2}>
                <Grid.Column><Container fluid style={{overflowY: 'auto', width: 400, maxHeight: 500 }}>
      <Table padded={false} sortable celled selectable>
          <Table.Body>
          {props.eventList ? (props.eventList.map(({_id, eventtitle, voicecmds, compatibleprod, uservariables}, index, array) => (
            <Table.Row key={index} 
            onClick = {async () => {
              const compats = await reFormComp(array[index].compatibleprod);
              setTableDataY(compats.tempComp)
              setSaveComplete(true)
              setSelectEvent(array[index]);
              setOGEvent({event: array[index], comp: reFormComp(array[index].compatibleprod).tempComp})
              }}>
        <Table.Cell textAlign='center'>
          {eventtitle} <br/>
          </Table.Cell>
          </Table.Row>
          ))) : <></>}
          </Table.Body>
          <Table.Footer>
          {/* <Table.Row>
            New Event
          </Table.Row> */}
          </Table.Footer>
        </Table></Container>
        </Grid.Column>
        <Grid.Column>
        {selectEvent && <CreateUpdateEvent saveComplete={saveComplete} setSaveComplete={setSaveComplete} OGEvent={OGEvent}
         refetchEvents={props.refetchEvents} tableChange={tableChange} setTableChange={setTableChange}
        setTableData={setTableDataY} tableData={tableDataY} 
        setSelectEvent={setSelectEvent} selectEvent={selectEvent}/>}
        </Grid.Column>
        </Grid.Row>
        </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Grid>
            <Grid.Row>
              <Grid.Column><Button
              content="DONE"
              labelPosition='right'
              icon='arrow right'
              onClick={() => {setOpen(false)}}
              /></Grid.Column></Grid.Row></Grid>
    
          </Modal.Actions>
        </Modal>
        </>
    
    )
}

export default EventEditor
//event editor/creator
//list events, edit name, voice cmds
//user variables, created by
//inherit product comps /other settings from other event

