import _ from 'lodash'
import React, {useEffect} from 'react'
import { useState } from 'react'
import { Container,Dropdown, Rating, Image, Card, Button, Table, Menu, Dimmer, Loader, Label, Icon, Popup, Grid } from 'semantic-ui-react'
import PreCompQuery from './product-class-query-precomp';
import buttonStyles from '../styles/components/Button.module.css';
import ServiceForm from './service-request-form'
import {SpecProductPopup, MenuPopup } from './spec-product-popup'

function compare (a, b) {
  if (a.className < b.className){
    return -1
  }
  if ( a.className > b.className ){
    return 1;
  }
  return 0;
}


function ProductTable(props) {
  // productPageQuery(props.activeFlow)
    //if button show compat table
  
  //add query based on input flow IDs[]
  const holdSelected = (id) => {
    if (id === props.activeClass) {
      return true
    } else {return false}
  }

  useEffect(() => {
    props.setRecMetaIndex(props.productClassData.first_meta)
  }, [])



  const [data, setRecData] = useState(props.productClassData.first)
  const [comboMeta, setComboMeta] = useState(props.productClassData.first_meta)
  const [activeList, setActiveList] = useState(1)
  const [comboSelection, setComboSelection] = useState({activeItem: "first"})
  // const { column, data, direction } = state
  const handleItemClick = (e, { name }) => {

    setComboSelection({ activeItem: name })

    if (name == "first")
    {     setRecData(props.productClassData.first)
          setComboMeta(props.productClassData.first_meta)
          props.setRecMetaIndex(props.productClassData.first_meta)
    }
    if (name == "second")
    {    setRecData(props.productClassData.second)
      setComboMeta(props.productClassData.second_meta)
      props.setRecMetaIndex(props.productClassData.second_meta)
      }
    if (name == "third")
    {   setRecData(props.productClassData.third)
        setComboMeta(props.productClassData.third_meta)
        props.setRecMetaIndex(props.productClassData.third_meta)
    }

  }
  var hClasses = []
  props.homeCL.forEach((HP) => {
    HP.productClass.forEach((pC) => {
      if (!hClasses.includes(pC._id)) 
      {hClasses.push(pC._id)}
    })
  })

  if (props.productClassData.error) { return (<h1>ERROR loading data</h1>)};

  let validRanks

  if (props.productClassData) {
    if (props.productClassData.first && props.productClassData.first.length !=0) {
      if (props.productClassData.second && props.productClassData.second.length !=0) {
        if (props.productClassData.third && props.productClassData.third.length !=0) {
          validRanks = 3
        } else {
          validRanks = 2
        }
      } else {
        validRanks = 1
      }
    }
  }

  

  // console.log(props.productClassData)

  // const testProd = comboMeta.pricedSpecs
  // console.log(testProd)
  //fix and add price to count opti
  return (
    <>
    <div style={{
      textAlign:'center',
      width: '100%',
      borderRadius: '5px',
      backgroundColor: 'white',
      opacity:'1',
      border: '1px solid lightgray',
      overflowY: 'auto',
      overflowX: 'hidden',
      padding: '0px',
      boxShadow: '1px 1px #b8b8b8',
      backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)"
    }}>
    
    {(props.mode=="WISH") ? <>
    <h4>
    <Button onClick={() => 
      {props.refreshAlter()}} icon>
        <Icon name='refresh' />
      </Button><br/>
      <br/>COMPATIBLE MIX OF DEVICES</h4>
    <div>for flows and home devices on this page</div><br/></>
    : <h4 style={{position: "relative", top:0}}><br/>COMPATIBLE MIX OF DEVICES
    <br/>
    
    <div style={{position:'relative', top:'0px', fontSize: '12px', fontWeight:'200'}}>
    {((props.sortComboState=="PRICE") ? <>prices are latest estimates and could change</> : <>more devices usually means longer installation</>)}</div>
      
      </h4>}
    
    <div>SORTED BY&nbsp;
    <Dropdown
        inline
        options={[{
          key: '1',
          text: 'COUNT',
          value: 'COUNT',
          content: 'COUNT',
        },
        {
          key: '2',
          text: 'PRICE',
          value: 'PRICE',
          content: 'PRICE',
        }]}
        value={props.sortComboState}
        onChange={(e, { value })=> {props.setSortComboState(value)}}
      /></div>{props.sortComboState=="PRICE" ? (<>CAD excluding tax<br/></>) : (<></>)}
    <Menu fluid widths={validRanks} secondary disabled={(props.productClassData.second.length==0)}>
        {(validRanks > 0) ? (<Menu.Item
          name='first'
          active={comboSelection.activeItem == "first"}
          onClick={handleItemClick}
        >
        {((props.sorter=="COUNT" || props.sortComboState=="COUNT") && (validRanks > 0)) ? 
        <><MenuPopup opti={props.productClassData.first_meta.opti} price={props.productClassData.first_meta.totalPrice} count={props.productClassData.first.length} trigger={(<>🍰</>)}/></>
        : <><MenuPopup sort={"PRICE"} opti={props.productClassData.first_meta.opti}
         price={props.productClassData.first_meta.totalPrice}
          count={props.productClassData.first.length}
           trigger={((props.productClassData.first_meta?.opti != props.productClassData.first_meta?.noHomeOpti)
            ? <Label color='blue' tag size='large'>
          <div style={{color: "#000000", textDecoration: 'line-through',
           textDecorationColor: "white",textDecorationThickness: '1px'}}>
          ${Intl.NumberFormat('en-US', {minimumFractionDigits: 2,
             maximumFractionDigits: 2}).format(props.productClassData.first_meta?.noHomeOpti)}</div>
          <div>${Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(props.productClassData.first_meta?.opti)}</div>
          </Label> : <Label color='blue' tag size='large'>
            <div>${Intl.NumberFormat('en-US', 
            {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(props.productClassData.first_meta?.opti)}</div></Label>)
            }/></>}
        </Menu.Item>) : (<></>)}

        {((validRanks > 1) ? (<Menu.Item
          name='second'
          active={comboSelection.activeItem == "second"}
          onClick={handleItemClick}
        >
      {((props.sorter=="COUNT"|| props.sortComboState=="COUNT") && (validRanks > 1)) ? 
        <><MenuPopup opti={props.productClassData.second_meta.opti} price={props.productClassData.second_meta.totalPrice} count={props.productClassData.second.length} trigger={(<>⭐️ ⭐️</>)}/></>
        : <><MenuPopup sort={"PRICE"} opti={props.productClassData.second_meta.opti}
        price={props.productClassData.second_meta.totalPrice}
         count={props.productClassData.second.length}
          trigger={((props.productClassData.second_meta?.opti != props.productClassData.second_meta?.noHomeOpti)
           ? <Label color='blue' tag size='large'>
         <div style={{color: "#000000", textDecoration: 'line-through',
          textDecorationColor: "white",textDecorationThickness: '1px'}}>
         ${Intl.NumberFormat('en-US', {minimumFractionDigits: 2,
            maximumFractionDigits: 2}).format(props.productClassData.second_meta?.noHomeOpti)}</div>
         <div>${Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(props.productClassData.second_meta?.opti)}</div>
         </Label> : <Label color='blue' tag size='large'>
           <div>${Intl.NumberFormat('en-US', 
           {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(props.productClassData.second_meta?.opti)}</div></Label>)
           }/></>}
        </Menu.Item>) : (<></>))}

        {((validRanks > 2) ? (<Menu.Item
          name='third'
          active={comboSelection.activeItem == "third"}
          onClick={handleItemClick}
        >
        {((props.sorter=="COUNT" || props.sortComboState=="COUNT")) ? 
        <><MenuPopup opti={props.productClassData.third_meta.opti} price={props.productClassData.third_meta.totalPrice} count={props.productClassData.third.length} trigger={(<>🤖 🤖 🤖</>)}/></>
        : <><MenuPopup sort={"PRICE"} opti={props.productClassData.third_meta.opti}
        price={props.productClassData.third_meta.totalPrice}
         count={props.productClassData.third.length}
          trigger={((props.productClassData.third_meta?.opti != props.productClassData.third_meta?.noHomeOpti)
           ? <Label color='blue' tag size='large'>
         <div style={{color: "#000000", textDecoration: 'line-through',
          textDecorationColor: "white",textDecorationThickness: '1px'}}>
         ${Intl.NumberFormat('en-US', {minimumFractionDigits: 2,
            maximumFractionDigits: 2}).format(props.productClassData.third_meta?.noHomeOpti)}</div>
         <div>${Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(props.productClassData.third_meta?.opti)}</div>
         </Label> : <Label color='blue' tag size='large'>
           <div>${Intl.NumberFormat('en-US', 
           {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(props.productClassData.third_meta?.opti)}</div></Label>)
           }/></>}
        </Menu.Item>) : <></>)}
    </Menu>
    <Container fluid style={{overflowY: 'auto', overflowX: 'hidden', maxHeight: 200} }>
      <div style={{borderTop: "2px solid lightgray"}}>
    <Table basic="very" padded celled selectable>
      <Table.Body>
        {(data.length==0 && !props.productClassData.flows.length==0) ? (<Dimmer active inverted><Loader /></Dimmer>)
        : (data && data.map(({ _id, className }, index) => {
          const respMeta = comboMeta.pricedSpecs.find(element => element.class == _id)

          return (
            <><Table.Row textAlign='center' key={index} onClick = {() => {props.changeClassSelection(_id);}} active = {holdSelected(_id)}>
            <Table.Cell><SpecProductPopup combo={respMeta.spec} className={className} hClasses={hClasses} id={_id}/></Table.Cell>
          </Table.Row>
          </>
        )}
        
        
        ))}
      </Table.Body>
      <Table.Footer>
      </Table.Footer>
    </Table></div></Container>
    </div>
</>
  )
}
//<ProductCard specData={QuerySpecProducts(id)}/>
export default ProductTable