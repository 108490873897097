import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { NhostClient, NhostReactProvider } from '@nhost/react'
import Layout from './components/Layout';
import SignIn from './pages/SignIn';
import Profile from './pages/Profile';
import ProtectedRoute from './components/ProtectedRoute';

// import 'semantic-ui-css/semantic.min.css';
import FlowFinder from './pages/FlowFinder';
import Landing from './pages/Landing';
import Wishlist from './pages/Wishlist';
import Make from './pages/Make';

import FlowDetails from './components/flow-details-page';
import { Container } from 'semantic-ui-react';

import { NhostApolloProvider } from '@nhost/react-apollo'

import ReactGA from 'react-ga';
ReactGA.initialize('UA-254462170-1');     //process.env.NEXT_PUBLIC_GA_TRACKING
ReactGA.pageview(window.location.pathname + window.location.search);

const nhost = new NhostClient({
  subdomain: process.env.REACT_APP_NHOST_SUBDOMAIN,
  region: process.env.REACT_APP_NHOST_REGION
})


function App() {
//get id of location if details/:
// const location = useLocation()
// var detailID
// console.log(location.pathname)
// if (location.pathname.split("details/:id").length > 1) {
//   detailID = location.pathname.split("details/:id")[1]
// }
  return (
    <>
    <NhostReactProvider nhost={nhost}>
    <NhostApolloProvider nhost={nhost}>
      <Container>
      <BrowserRouter>
        <Routes>
        <Route path="landing" element={<Landing />} />
          <Route path="login" element={<SignIn />} />
          <Route 
            path="/"
            element={
              <ProtectedRoute>
                <Layout />
              </ProtectedRoute>
            }>
            <Route index element={<FlowFinder />} />
            <Route path="details/:flowkey" element={<FlowDetails/>}/>
            <Route path="flowfinder" element={<FlowFinder />} />
            <Route path="profile" element={<Profile />} />
            <Route path="wishlist" element={<Wishlist/>} />
            <Route path="details" element={<FlowDetails />} />
            <Route path="make" element={<Make />} />
          </Route>
        </Routes>
      </BrowserRouter>
      </Container>
      </NhostApolloProvider>
      </NhostReactProvider>
      <Toaster />
    </>
  );
}

export default App;
