import { gql, useQuery } from '@apollo/client';
// import { gql } from 'graphql-tag';

const GET_FUNCTION_FLOWS = gql`
query filterFunction ($functionTag: String!, $offset: Int, $limit: Int, $uID: String) {
  flowsbyFunction ( functionTags: $functionTag, offset: $offset, limit: $limit, uID: $uID) {
    _id
    basename
    feature
    description
    troubleshoot
    status
    ratings{
      degree
      criteria
    }
    createdby{
      alias
    }
    wishStat
  }
}
`;

function QueryFlowsTable (functionTag, offset, limit, mongoID) {
  const { 
      loading: loadingAllFlows, 
      error: errorAllFlows, 
      data: allFlows,
      refetch } = useQuery(GET_FUNCTION_FLOWS,
        {variables: { functionTag, offset, limit, uID: mongoID }},)
    
    // console.log(functionTag)
    // console.log(allFlows)

    if (loadingAllFlows) { return ('Loading...') };
    if (errorAllFlows) { return ('ERROR')};

    const flowsLoaded = allFlows.flowsbyFunction.map(flow => {
      return {
      id: flow._id,
      basename: flow.basename,
      feature: flow.feature,
      description: flow.description,
      ratings: flow.ratings,
      makerAddr: flow.createdby.address,
      makerAlias: flow.createdby.alias,
      wishStat: flow.wishStat,
      status: flow.status,
      troubleshoot: flow.troubleshoot
      };});
      
    const flowRows = flowsLoaded.filter((flow) => (flow.status!="proposed"))

// && (flow.feature === "BASE" || functionTag=="untagged"))
    return (
      {flowsLoaded: flowRows, loadingAllFlows, errorAllFlows, refetch}
    )}

export default QueryFlowsTable