import styles from '../styles/components/Layout.module.css';

import { useState, useEffect, Fragment } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import buttonStyles from '../styles/components/Button.module.css';
import { useAuthenticationStatus } from '@nhost/react'

import {
  ChevronDownIcon,
  HomeIcon,
  LogoutIcon,
  UserIcon,
  HeartIcon
} from '@heroicons/react/outline';
import Avatar from './Avatar';
import { useSignOut } from '@nhost/react';
import { useUserId } from '@nhost/react'


import { gql, useQuery } from '@apollo/client'

const GET_USER_QUERY = gql`
  query GetUser($id: uuid!) {
    user(id: $id) {
      id
      email
      displayName
      metadata
      avatarUrl
    }
  }
`

const Layout = () => {
  const { isAuthenticated, isLoading } = useAuthenticationStatus()

  const id = useUserId()

  const { loading, error, data } = useQuery(GET_USER_QUERY, {
    variables: { id },
    skip: !id
  })
  const user = data?.user

  const { signOut } = useSignOut();

  const menuItems = [
        {
      label: 'Profile',
      href: '/profile',
      icon: UserIcon,
    },{
      label: 'Landing Page',
      href: '/',
      icon: HeartIcon,
    },{
      label: 'Flow Finder',
      href: '/flowfinder',
      icon: HomeIcon,
    },
    {
      label: 'Wishlist',
      href: '/wishlist',
      icon: HeartIcon,
    },
    {
      label: 'Logout',
      onClick: signOut,
      icon: LogoutIcon,
    }
  ];

const [buttonContent, setButtonContent] = useState()
const [buttonOut, setButtonOut] = useState()
const [goStat, setGoStat] = useState()
const [title, setTitle] = useState()

const history = useNavigate()
useEffect(() => {

  if (window.location.pathname=="/flowfinder" || window.location.pathname=="/") {
    setButtonContent("Wishlist")
    setButtonOut("/wishlist")
    setGoStat(true)
    setTitle("Flow Finder")
  } else if (window.location.pathname=="/wishlist") {
    setButtonContent("Flow Finder")
    setButtonOut("/flowfinder")
    setGoStat(true)
    setTitle("Wishlist")

  } else if ((window.location.pathname=="/details") || (window.location.pathname.substring(0,9)=="/details/")) {
    setButtonContent("Flow Finder")
    setButtonOut("/flowfinder")
    setGoStat(true)
    setTitle("Flow Details")
  } else if (window.location.pathname=="/profile") {
    setButtonContent("Flow Finder")
    setButtonOut("/flowfinder")
    setGoStat(true)
    setTitle("Profile") }
    else {
      setButtonContent("Flow Finder")
      setButtonOut("/flowfinder")
      setGoStat(true)
      setTitle("")
  }
},[history])

  return (
    <div>
      <header className={styles.header}>
        <div className={styles['header-container']}>
          <Link to="/landing">
            <img className={styles['logo-wrapper']} src={process.env.PUBLIC_URL + 'logo.png'} alt="logo" />
            <a style={{color: 'black', position:'relative', bottom:'30px', textAlign:'left'}}>beta</a>
          </Link><div className={buttonStyles['title-grad-navi']}>{title}</div><div className={buttonStyles['btn-grad-navi-container']}><>{(goStat==true) && (<>GO TO<br/></>)}</><></>
          <div><Link to={buttonOut}><button className={!goStat ? buttonStyles['btn-grad-navi'] : buttonStyles['btn-grad-navi-air']}>{buttonContent}</button></Link></div></div>
          {isAuthenticated ? (<Menu as="div" className={styles.menu}>
            <Menu.Button className={styles['menu-button']}>
              <Avatar src={user?.avatarUrl} alt={user?.displayName} />
              <ChevronDownIcon />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter={styles['menu-transition-enter']}
              enterFrom={styles['menu-transition-enter-from']}
              enterTo={styles['menu-transition-enter-to']}
              leave={styles['menu-transition-leave']}
              leaveFrom={styles['menu-transition-leave-from']}
              leaveTo={styles['menu-transition-leave-to']}
            >
              <Menu.Items className={styles['menu-items-container']}>
                <div className={styles['menu-header']}>
                  <Avatar src={user?.avatarUrl} alt={user?.displayName} />
                  <div className={styles['user-details']}>
                    <span>{user?.displayName}</span>
                    <span className={styles['user-email']}>{user?.email}</span>
                  </div>
                </div>
                <div className={styles['menu-items']}>
                  {menuItems.map(({ label, href, onClick, icon: Icon }) => (
                    <div key={label} className={styles['menu-item']}>
                      <Menu.Item>
                        {href ? (
                          <Link to={href}>
                            <Icon />
                            <span>{label}</span>
                          </Link>
                        ) : (
                          <button onClick={onClick}>
                            <Icon />
                            <span>{label}</span>
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>) : (<></>)}
        </div>
      </header>

      <main className={styles.main}>
        <div className={styles['main-container']}>
        {error ? (
            <p>Something went wrong. Try to refresh the page.</p>
          ) : !loading ? (
            <Outlet context={{ user }} />
          ) : null}
        </div>
      </main>
    </div>
  );
};

export default Layout;
