import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';


//function to query productClass and get specific products
const GET_HOME_PRODUCTS = gql`
query HP ($userID: String!) {
homeProducts (userID:$userID) {
    _id
    make
    model
    urlPurchase
    price
    photoURL
    nickName
    productClass{
      _id
      className
    }
  }
}
`;

function QueryHomeProducts (userID) {
  const { 
      loading: loadingSpecs, 
      error: errorSpecs, 
      data: allSpecs, refetch: refetchHP } = useQuery(GET_HOME_PRODUCTS,
        {variables: { userID }},)
    
    if (loadingSpecs) { return ('Loading...') };
    if (errorSpecs) { return ('ERROR')};
    let allRows = []
    if (allSpecs.homeProducts) {allRows = allSpecs.homeProducts}
    else {allRows = []}

    const specRows = allRows.map(spec => {
      return {
      _id: spec._id,
      make: spec.make,
      model: spec.model,
      urlPurchase: spec.urlPurchase,
      photoURL: spec.photoURL,
      nickName: spec.nickName,
      price: spec.price,
      currency: spec.currency,
      productClass: spec.productClass
      };});
    return ({specRows, loadingSpecs, errorSpecs, refetchHP}
    )}

export default QueryHomeProducts