import React from 'react'
import { Popup, Icon, Label } from 'semantic-ui-react'


function prepRatings (ratingsArray) {
//find icons
const icons = []

for (let i = 0; i < ratingsArray?.ratingsArray?.length; i++) {
  if (ratingsArray.ratingsArray[i].criteria === "security") {
  icons.push("lock");
  } else if (ratingsArray.ratingsArray[i].criteria === "energy") {
    icons.push("leaf");
  } else if (ratingsArray.ratingsArray[i].criteria === "users") {
    icons.push("users");
  } else if (ratingsArray.ratingsArray[i].criteria === "setup") {
    icons.push("chain");
  } else if (ratingsArray.ratingsArray[i].criteria === "voice") {
    if (ratingsArray.ratingsArray[i].degree === "enabled") {
      icons.push("microphone");
    } else if (ratingsArray.ratingsArray[i].degree === "disabled") {
      icons.push("microphone slash");
    }
  } else {
    icons.push("tag");
  }
}

//TODO: need to rank ratings for render

//returns ratings formatted as labels
    return (ratingsArray?.ratingsArray?.map((rating, index) => (
      <a><Popup
      trigger={<Label size={"tiny"} key = {index}><Icon name={icons[index]} />{rating.degree}</Label>}
      content={<div>{rating.criteria}: {rating.degree}</div>}
      size='mini'
      />
      </a>
    )) )
}


export default prepRatings

//energy, security, setup, users