import _ from 'lodash'
import React, { useState } from 'react'
import { Dimmer, Card, Loader } from 'semantic-ui-react'

import FlowCard from './flow-card'
import { gql } from "@apollo/client";

import ReactGA from "react-ga";



//
// const authLink = setContext((_, { headers }) => {
//   const token = '< your token here >';
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : ''
//     }
//   };
// });



const GET_FUNCTION_FLOWS = gql`
query filterFunction ($functionTag: String!, $offset: Int, $limit: Int, $uID: String) {
  flowsbyFunction ( functionTags: $functionTag, offset: $offset, limit: $limit, uID: $uID) {
    _id
    basename
    feature
    description
    troubleshoot
    ratings{
      degree
      criteria
    }
    createdby{
      address
      alias
    }
    wishStat
  }
}
`;

//TODO: ADD tabs for categories
//breadcrumbs find/select - personalize - submit order
//add ratings stars that users can use (star for each flow)
function sortableReducer(state, action) {
  switch (action.type) {
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          direction:
            state.direction === 'ascending' ? 'descending' : 'ascending',
        }
      }
      return {
        column: action.column,
        data: _.sortBy(state.data, [action.column]),
        direction: 'ascending',
      }
    default:
      throw new Error()
  }}

function FlowTable(props) {

//create state for flow data being displayed
const [flows, setFlows] = useState([])
if (flows.length==0 && !props.flowsLoaded.length==0)
{
  setFlows(props.flowsLoaded)}

  // var data = flows
  //if wishlist, filter for wishStat=="yes"
  if (props.wishlist==true)
  {
    var data = flows.filter((f) => f.wishStat=="yes")} else
  { var data = props.flowsLoaded }

// var wishStatus = props.wishlist



const handleAddWish = async (args) => {

  const {flowID, mongoID, flowIndex} = args

  let newWishStat = wishState.map((wishStatus, index) => {

    if (index==flowIndex) {
      return "yes"
    } else {
      return wishStatus
    }
  })
  setWishState(newWishStat)

  ReactGA.event({
    category:"WISH",
    action:"ADD WISH",
    label:`flowID:${flowID};mid:${mongoID}`
  })

try {

  await props.addFlow({
  variables: {
  addFlow: {
    flowID: flowID,
    assignedUser: mongoID
  }},
});
  //find flowID in stateFlows and change invert wishStat

} catch(err) {console.log(err)}
    }

    const handleDelWish = async (args) => {

     

      const {flowID, mongoID, flowIndex} = args

      let newWishStat = wishState.map((wishStatus, index) => {

        if (index==flowIndex) {
          return "no"
        } else {
          return wishStatus
        }
      })
      setWishState(newWishStat)
      ReactGA.event({
        category:"WISH",
        action:"DELETE WISH",
        label:`flowID:${flowID};mid:${mongoID}`
      })
      try {
        await props.delFlow({variables: {
        delFlow: {
          flowID: flowID,
          assignedUser: mongoID
        }}});
        props.refetchFlows()
        //find flowID in stateFlows and change invert wishStat
        const newFState = flows.map(obj => {
          if (obj.id == flowID) {
            return {...obj, wishStat:"no"}
          }
          return obj;
        })
        setFlows(newFState)
      } catch(err) {console.log(err)}
      
        }

  const holdSelected = (id) => {
    if (id === props.activeFlow) {
      return true
    } else {return false}
  }

  const wishStatArray = data.map((flow) => {
    return flow.wishStat
  })

  const [wishState, setWishState] = useState(wishStatArray)


  if (props.error) { return (<h3>ERROR loading data</h3>)};
  
  return (
    <>
       <Card.Group style={{maxHeight:'100vh', overflowY:'auto', overflowX: 'hidden'}} itemsPerRow={2}>
        {(!data) ? (<Dimmer active inverted><Loader /></Dimmer>)
          : (data.map(({ id, basename, description, feature, ratings, wishStat, troubleshoot}, index) => (
              <FlowCard flowIndex={index} holdSelected={holdSelected} changeSelection={props.changeSelection} setEmbedVideo={props.setEmbedVideo} width={props.width} feature={feature} mongoID={props.mongoID} wishlist={props.wishlist} delFlow={handleDelWish} addFlow={handleAddWish}
              loadingWishChange={props.loadingDeleteFlow || props.loadingAddFlow}
              troubleshoot={troubleshoot} wishStat= {props.wishlist ? "yes" : wishState[index]} id={id} basename={basename}
              ratings={ratings} description={description}></FlowCard>

        )))}</Card.Group>
        </>
  )
}

export default FlowTable