import { React } from 'react'
import ExpandYTable from '../components/expand-y-table'

import { gql, useQuery, useMutation } from "@apollo/client";
import { toast } from 'react-hot-toast'

import { Form, Dropdown, Button, Segment, Grid } from 'semantic-ui-react'
const mongoose = require('mongoose')

const UPDATE_EVENT = gql`
mutation UpdateEvent($eventUpdate: EventUpdate) {
  updateEvent(eventUpdate: $eventUpdate) {
    _id
  }
}
  `;
//query to find dropdown product specs
const GET_CLASSES = gql`
  query allClasses {
    products  {
      _id
      className
    }
  }
`;

const reFormComp = (comp) => {
    var tempComp = [[]]
    comp.forEach(async (xProd,xIndex) => {
      var tempY = []
      await xProd.forEach((yProd, yIndex) => {
  
        tempY.push(yProd._id)
      })
      tempComp[0].push({text: tempY})
    })
    return {tempComp}
  }

  function QueryClassProducts () {
    const { 
      loading: loadingClasses, 
      error: errorClasses, 
      data: allClasses } = useQuery(GET_CLASSES)
    
    if (loadingClasses) { return ('Loading...') };
    if (errorClasses) { return ('ERROR')};
    
    return ({allClasses, loadingClasses, errorClasses}
      )}
const CreateUpdateEvent = (props) => {

    const [updateEvent, { 
      loading: loadingUpdateEvent, 
      error: errorUpdateEvent}] = useMutation(UPDATE_EVENT)
  
  const allClasses = QueryClassProducts()
  
  
  const allClas = reFormComp(props.selectEvent.compatibleprod)
  
  
  //state variables for event updates
  //props.selectEvent
  //props.tableData (product Class data)
  //convert user var string into comma seperated array
  //check if changes to original selectEvent
  //check if event updates will accept IDs only
  
  const formatMongo = (prodClassXY) => {
    var tempXY = []
    prodClassXY[0].forEach((x) => {
      var temp = []
      x.text.forEach((y) => {
        temp.push(mongoose.Types.ObjectId(y))
      })
      tempXY.push(temp)
    })
    return tempXY
  }
  const userVars = () => {
    if (props.selectEvent.uservariables.length==1){
      return (props.selectEvent.uservariables[0].split(','))
    } else {
      return (props.selectEvent.uservariables)
    }}
  
  
  
  function arrayEquals(a, b) {
    return (a.every((val, index) => val.text === b[index].text))
  }
  
  const changeStatus = () => {  
  
    return ((props.OGEvent.event.eventtitle == props.selectEvent.eventtitle)
    && (props.OGEvent.event.uservariables == props.selectEvent.uservariables) &&
    (arrayEquals(props.OGEvent.comp[0], props.tableData[0])))
  }
  
  
  const saveChanges = () => {
    try {
    const eventSubmit = {
        _id: props.selectEvent._id,
        eventtitle: props.selectEvent.eventtitle,
        uservariables: userVars(),
        compatibleprod: formatMongo(props.tableData)
      }
      updateEvent({variables: { eventUpdate: eventSubmit }
        , onCompleted: () => {
          props.refetchEvents()
          props.setSaveComplete(false)
          toast.success('Event updated!', { id: 'updateEvent' })
        }})
  
  }
    catch (err) {
      toast.error('Unable to update event', { id: 'updateEvent' })
      console.log(err)
    }
  }
  
  
  var n = props.selectEvent.voicecmds.length;
  const commandInfo = [...Array(n).keys()]
  
  const commandDrops = props.selectEvent.voicecmds.map((vc, index) => {
    return {
      key: index,
      text: vc.command,
      value: index
    }
  })
  
  // const [changeStat, setChangeStat] = useState(false)
  
  //fix format classes if one row is fully deleted
  //only show save changes when info changed
  
    return (
      <Form>
              <Form.Group widths='equal'>
                  <Segment>
                      <Grid centered>
                      {(!changeStatus() && props.saveComplete) ? (<Grid.Row>
          <Button
            content="SAVE CHANGES"
            labelPosition='right'
            icon='checkmark'
            onClick={() => {saveChanges()}}
            positive />
                      </Grid.Row>) : (<></>)}
                          <Grid.Row>
                          <Grid columns={2}>
                          <Grid.Column textAlign='center'>
                            {props.selectEvent._id}
                          <Form.Input
                              label='Title'
                              control='input'
                              value={props.selectEvent.eventtitle}
                              onChange={(e) => {
                                let temp = {...props.selectEvent}
                                temp.eventtitle = e.target.value
                                props.setSelectEvent(temp)}
                              }
                          />
                          <Form.Input
                              label='Event Type'
                              disabled
                              value={props.selectEvent.actiontrigger}
                              onChange={(e) => {
                                var temp = {...props.selectEvent}
                                temp.actiontrigger = e.target.value
                                props.setSelectEvent(temp)}}
                          />
                          </Grid.Column>
                          <Grid.Column textAlign='center'>
                            Compatible Product Combinations
                          {allClasses.allClasses && allClas.tempComp[0] && props.selectEvent &&
                         <ExpandYTable change={changeStatus} selectEvent={props.selectEvent} setTableChange={props.setTableChange} tableChange={props.tableChange}
                           allClasses={allClasses.allClasses} tableData={props.tableData} mode={"EVENT"}
                           setTableData={props.setTableData} xIndex={0}/>}
                          </Grid.Column>
                          </Grid>
                          </Grid.Row>
                          <Form.Input
                              control='input'
                              value={props.selectEvent.uservariables}
                              label='User Variables (shown as [variable] in event title)'
                              onChange={(e) => {
                                var temp = {...props.selectEvent}
                                temp.uservariables = [e.target.value]
                                props.setSelectEvent(temp)}}
                          />
                          {(commandDrops.length!=0) ? (<Grid.Row>
                            Voice Commands<br/>
                          <Dropdown
                            value={commandInfo}
                            multiple
                            options={commandDrops}
                            noResultsMessage="No voice commands yet"
                          />
                          </Grid.Row>) : (<></>)}<Grid.Row></Grid.Row>
                      </Grid>
              </Segment>
              </Form.Group>
              </Form>
    )
  }

  export default CreateUpdateEvent