import React, { useEffect, useState, useCallback } from 'react';
import FlowGraph from '../components/node.components';
import { Container } from 'semantic-ui-react';
import { Button, Icon } from 'semantic-ui-react';
import GetEventInputs from '../components/get-event-inputs';
import { applyNodeChanges, applyEdgeChanges, addEdge } from 'react-flow-renderer';
import FlowInputs from '../components/get-flow-inputs';

import { useOutletContext } from 'react-router-dom';


const initNode = [
  {
    id: '1',
    type: 'input',
    data: { label: 'START' },
    position: { x: 0, y: 0 }
  }
]



const Make = () => {
  const { user } = useOutletContext();
  const [flow, setFlow] = useState(initNode);

  const { render: renderEventInputs, eventArray, node } = GetEventInputs(user.metadata.mid);
  // add node infomation to flow if the node information is not empty and the node is not already in the flow
  useEffect(() => {
    if (node && !nodes.find(n => n.id === node.id)) {
      setNodes([...nodes, node]);
    }
    // if (node && edges.length>0 && !edges.find(e => e.id === node.id)) {
    //   setEdges([...edges, node]);
    // }
  }, [node]);

  const nodeProps = initNode.filter((ele) => (ele.id.slice(0,1) !== "e"))
  
  const edgeProps = initNode.filter((ele) => (ele.id.slice(0,1) == "e"))

  const [nodes, setNodes] = useState(nodeProps);
  const [edges, setEdges] = useState(edgeProps);

  const onNodesChange = useCallback(
    (changes) => {setNodes((ns) => applyNodeChanges(changes, ns))},
    []
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((es) => applyEdgeChanges(changes, es)),
    []
  );
  const onConnect = useCallback((connection) => setEdges((eds) => addEdge(connection, eds)));


  // const onConnect = (params) => {
  //   setFlow(e => addEdge(params, e))
  //   console.log('flow', flow);
  // }

  // reset to initial node
  const resetNodes = () => {
    setNodes(initNode)
    setEdges([])
  }
  return(
    <Container>
      <FlowGraph 
      onEdgesChange={onEdgesChange}
      onNodesChange={onNodesChange}
        elementsProps={flow}
        nodeProps={nodes}
        edgeProps={edges}
        onConnectProps={onConnect}
        styleProps={{width: '100%', height: '90vh'}} />
      {(user.id == "374fd833-f742-4183-97a7-f0d135c08e3b") ? renderEventInputs : <></>}
      <Button basic color='red' animated='fade' onClick={resetNodes}>
        <Button.Content visible>Reset</Button.Content>
        <Button.Content hidden>
          <Icon name='redo' />
        </Button.Content>
      </Button>
      {(user.id == "374fd833-f742-4183-97a7-f0d135c08e3b") && eventArray && flow ?
      <FlowInputs eventArrayProps={eventArray} setNodes={setNodes}
      setEdges={setEdges} nodes={nodes} edges={edges} flowProps={nodes.concat(edges)} /> : null}
    </Container>
  )
}

export default Make;