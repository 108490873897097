//form to edit flow details, basename, feature name, functionTags, ratings, status, description
import React, { useState, useEffect } from 'react';
import { Form, Icon, Image, Button, Modal, Grid, Container, Dimmer, Loader } from 'semantic-ui-react';
import { useQuery, useMutation } from "@apollo/client";
import { gql } from 'graphql-tag';
import buttonStyles from '../styles/components/Button.module.css';

import { toast } from 'react-hot-toast';
import styles from '../styles/components/AddProductCard.module.css';

const UPDATE_FLOW = gql`
mutation updateFlow ($flowUpdate: FlowUpdate) {
  updateFlow ( flowUpdate: $flowUpdate) {
    _id
}}
`;

const REQUEST_FLOW = gql`
  mutation SubmitFlow($flowInput: FlowInput) {
    createFlow(flowInput: $flowInput) {
      _id
    }
  }
`;

const ADD_RATING = gql`
mutation AddRating($ratingInput: RatingInput) {
  addRating(ratingInput: $ratingInput) {
    _id
    attachID
    criteria
  }
}
`;

const DEL_RATING = gql`
mutation DelRating($ratingId: String) {
  delRating(ratingID: $ratingId)
}`

//takes in flowID, queries and returns above information and saves into state
const FlowEdit = (props) => {
    // const flowID = "61c709f100c936e1d96138cb";
    // const {baseFeatures: data, loadingBaseFeatures: loading, errorBaseFeatures: error} = FlowDetailsQuery(flowID)
    // console.log(props.flowArray)
    //declare state variables for each field
    const [feature, setFeature] = useState(props.flowArray.feature);
    const [basename, setBasename] = useState(props.flowArray.basename);
    const [status, setStatus] = useState(props.flowArray.status);
    const [description, setDescription] = useState(props.flowArray.description);
    const [troubleshoot, setTroubleshoot] = useState(props.flowArray.troubleshoot);
    const [roomTags, setRoomTags] = useState(props.flowArray.roomtags);
    const [functionTags, setFunctionTags] = useState(props.flowArray.functiontags);
    const [ratings, setRatings] = useState()
    
    const refRatingID = []
    const ratingString = props.flowArray.ratings.map((r) => {
      refRatingID.push(r._id)
      return (`${r.degree}::${r.criteria}`)
    })
    const refFunctionTags = props.flowArray.functiontags
    //init variable
    const initVars = () => {
      const ratingString = props.flowArray.ratings.map((r) => {

        return (`${r.degree}::${r.criteria}`)
      })
    setFeature(props.flowArray.feature)
    setBasename(props.flowArray.basename)
    setStatus(props.flowArray.status)
    setDescription(props.flowArray.description)
    setTroubleshoot(props.flowArray.troubleshoot)
    setRoomTags(props.flowArray.roomtags)
    setFunctionTags(props.flowArray.functiontags)
    setRatings(ratingString)
    }
    const [open, setOpen] = useState(false)
    const [updateFlow, { 
      loading: loadingUpdate, 
      error: errorUpdate}] = useMutation(UPDATE_FLOW)

    const [addRating, {
      loading: loadingRatingAdd,
      error: errorRatingAdd}] = useMutation(ADD_RATING)

      const [delRating, {
        loading: loadingRatingDel,
        error: errorRatingDel}] = useMutation(DEL_RATING)
      
    const [createFlow, { loading: submissionLoad, error: submissionError}] = useMutation(REQUEST_FLOW)

      // addRating({
      //   variables: {
      //     ratingInput: {
      //       attachID: "61c709f100c936e1d96138cb",
      //       criteria: "TEST",
      //       degree: "432"
      //     }
      //   }
      // })


      const ratingCheckUpdates = (initial, current) => {
        
        //convert to arrays of "  ::  "
        var tempCurrent = current
        if (typeof current === 'string' || current instanceof String)
        {
          tempCurrent = current.split(",")
        }

          //find missing to delete
          initial.forEach((i, iIndex) => {
            let foundStat = false
            tempCurrent.forEach((c, cIndex) => {
              if (i == c) {
                foundStat = true
              }
              if (cIndex == tempCurrent.length-1) {
                if (!foundStat) {

                  delRating({variables: {ratingId: refRatingID[iIndex]}})
                }
              }
            })
          })

          //find new to add
          tempCurrent.forEach((cur, curIndex) => {
            let foundStat = false
            initial.forEach((i, inIndex) => {
                if (i == cur) {
                  foundStat = true
                }

                if (inIndex == initial.length-1) {

                  if (!foundStat) {
          
                    //prepare rating Input
                    const ratingSplit = cur.split("::")
                    if (ratingSplit.length == 2 )
                    {
                      const ratInput = {
                      attachID: props.flowArray.id,
                        criteria: ratingSplit[1],
                        degree: ratingSplit[0]
                      
                    }
                          addRating({
                                      variables: {
                                        ratingInput: ratInput
                                      }
                                    })
                                  }
                  }
                }


            })
          })
      }

    const handleSubmit = () => {
      try {
        let tempFT = []
        ratingCheckUpdates(ratingString, ratings)
        if (refFunctionTags != functionTags) {
          tempFT = functionTags.split(',')
        } else { tempFT = refFunctionTags}
        updateFlow({
        variables: {
          flowUpdate: {
            _id: props.flowArray.id,
            basename: props.flowArray.basename,
            feature: feature,
            description: description,
            troubleshoot: troubleshoot,
            status: status,
            functionTags: tempFT
          }
        },
        onCompleted: () => {
          setOpen(false);
          props.refetch()
          //props.refetch({ flowID: props.flowArray.id })
          toast.success('Flow updated!', { id: 'updateFlow' })
        }
      });

    } catch (error) {
        console.log(error)
      }}
      const handleRequestSubmit = () => {
        try {
          // ratingCheckUpdates(ratingString, ratings)
          let flowInput = {
            eventDeps: ["63ba15ab7bca981d32957550"],
            basename: basename,
            feature: feature,
            status: status,
            description: description,
            mid: props.mongoID
          }
          createFlow({
            variables: {
              flowInput: flowInput
            },
          onCompleted: () => {
            setOpen(false);
            props.refetch()
            toast.success('Flow request submitted!', { id: 'requestFlow' })
          }
          })  
  
      } catch (error) {
          console.log(error)
        }}
    //TODO: close modal when submission is complete, react hook errors
    //container for event list

    const photoURL = `https://raw.githubusercontent.com/sarulnat/public-assets/main/${props.flowArray.id}.png`
    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => {initVars();setOpen(true)}}
        open={open}
        trigger={props.mode=="NEW REQUEST" ? <div className={buttonStyles['btn-grad-wishlist']}>Request Feature</div> : <div style={{cursor:'pointer'}}>{props.feature}</div>}
        >
        <Modal.Header>{props.mode=="NEW REQUEST" ? "Add a request" : "Edit your flow"}</Modal.Header>
        <Modal.Content image>
        <><div className={styles['image-crop-flow']}><Image bordered src={photoURL}/></div></>
            <Form>
            <Form.Group widths='equal'>
              
            {props.mode=="NEW REQUEST" ? <></> : (<><Form.Input
            disabled={props.mode=="NEW REQUEST"}
            value={basename}
            label='Basename'
            placeholder='Basename'
            onChange={(e) => setBasename(e.target.value)}
            /></>)}
            <Form.Input
            value={feature}
            label={props.mode=="NEW REQUEST" ? "Name the new flow" : 'Feature'}
            placeholder={props.mode=="NEW REQUEST" ? "Name the new flow" : 'Feature'}
            onChange={(e) => setFeature(e.target.value)}
            />
            {props.mode=="NEW REQUEST" ? <></> : (<Form.Input
            disabled={props.mode=="NEW REQUEST"}            
            value={status}
            label='Status'
            placeholder='Status'
            onChange={(e) => setStatus(e.target.value)}
            />)}
            </Form.Group>
            <Form.TextArea
            value={description}
            label={props.mode=="NEW REQUEST" ? 'Describe your request and needs as much as possible' : 'Description'}
            placeholder='Description'
            onChange={(e) => setDescription(e.target.value)}
            />
            {props.mode=="NEW REQUEST" ? (<></>) : (<><Form.TextArea
            value={troubleshoot}
            label='Troubleshooting tips and instructional videos'
            placeholder='Troubleshoot'
            onChange={(e) => setTroubleshoot(e.target.value)}
            />
            <Form.Group widths='equal'>
            <Form.Input
            value={roomTags}
            label='Room Tags'
            placeholder='Room Tags'
            onChange={(e) => setRoomTags(e.target.value)}
            />
            <Form.Input
            value={functionTags}
            label='Function Tags'
            placeholder='Function Tags'
            onChange={(e) => setFunctionTags(e.target.value)}
            />
            <Form.Input
            value={ratings}
            label='Ratings'
            placeholder='Ratings'
            onChange={(e) => setRatings(e.target.value)}
            />
            </Form.Group></>)}
            {(props.mode=="NEW REQUEST" ? <></> : <div>{props.flowArray.id}</div>)}
        </Form>
        </Modal.Content>
        <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          content="Submit changes"
          labelPosition='right'
          icon='checkmark'
          onClick={() => {props.mode=="NEW REQUEST" ? handleRequestSubmit() : handleSubmit()}}
          positive
        />
      </Modal.Actions>
      </Modal>
    )

}
//display state in form, updates to text fields change state
export default FlowEdit
//when submit, record is updated with state