import React, { useState, useEffect } from 'react'
import { Form, Dropdown, Button, Icon, Dimmer, Grid, Container, Table, Pagination, Loader } from 'semantic-ui-react'

//add dropdown option for multi select dropdown classes

const DropdownClassSpecMakeModel = (props) => {

  const productSpecs = props.allSpecs.allSpecs.specProducts

    //adjust productspecs x each product class option
  var newClassSpecs = []
  productSpecs.forEach((spec) => {
  spec.productClass.forEach((c) => {
    newClassSpecs.push({
    ...spec,
    productClass: c
    })})
    })
                                                
    const dropdownOpts = newClassSpecs.map(
      (prod,index) => ({
        key: index,
        text: `${prod.productClass.className} | ${prod.make} | ${prod.model}`,
        value: index
      })
    )
    // set event name, action or trigger, and id of event selected
    const handleChange = (p, {value}) => {

      var temp = props.tableData

      temp[props.xIndex][props.yIndex].text=newClassSpecs[value].productClass._id
      props.setTableData(temp)
      //    props.setTableChange(props.tableChange+1)
    }
    return (
      <Dropdown
        placeholder="select the product type / make / model"
        selection
        search
        clearable
        options={dropdownOpts}
        noResultsMessage="No results found, add new product"
        onChange={handleChange}
      />
    );
    }

    const DropdownClasses = (props) => {

      const productClasses = props.allClasses.products

        const dropdownOpts = productClasses.map(
          (prod) => ({
            key: prod._id,
            text: `${prod.className}`,
            value: prod._id
          })
        )
        const handleChange = (p, {value}) => {
          var temp = props.tableData
          temp[0][props.yIndex].text=value
          props.setTableData(temp)
          props.setTableChange(props.tableChange+1)
        }

        
        return (
          <Dropdown
            placeholder="select the product type"
            selection
            multiple
            value={props.tableData[0][props.yIndex].text}
            search
            clearable
            options={dropdownOpts}
            noResultsMessage="No results found, add new product"
            onChange={handleChange}
          />
        );
        }

    const ExpandYTable = (props) => {
    
            const holdSelected = (id) => {
            if (id ) {
              return true
            } else {return false}
          }
          
          
          const addNewItem = async () => {
            var tempData = props.tableData
            tempData[props.xIndex].push({text:""})
            await props.setTableData(tempData)
            props.setTableChange(props.tableChange+1)
          }

          const delNewItem = async () => {
            var tempXY = props.tableData
            
            await tempXY.splice(props.xIndex,1,props.tableData[props.xIndex].slice(0,props.tableData[props.xIndex].length-1))

            await props.setTableData(tempXY)
            
            props.setTableChange(props.tableChange+1)

          }

          const submitTable = () => {

          }



        return (<>
        <Table padded={false} sortable celled selectable>
          <Table.Body>
            {props.tableChange && props.tableData[props.xIndex] && 
            (props.tableData[props.xIndex].map(({text}, index) => 
            (<Table.Row key={index} onClick = {() => {}} active = {holdSelected()}>
                <Table.Cell textAlign='center'>
                {(props.mode!=="EVENT") ? (props.allSpecs && (props.allSpecs != 'Loading...') && (props.allSpecs != 'ERROR') &&
                <DropdownClassSpecMakeModel tableData={props.tableData} setTableData={props.setTableData} tableChange={props.tableChange} setTableChange={props.setTableChange}
                 xIndex={props.xIndex} allSpecs={props.allSpecs} yIndex={index}/>)
                 : (props.selectEvent && props.allClasses && (props.allClasses != 'Loading...') && (props.allClasses != 'ERROR') &&
                 <DropdownClasses change={props.change} mode={props.mode} tableData={props.tableData} setTableData={props.setTableData} tableChange={props.tableChange} setTableChange={props.setTableChange}
                  xIndex={props.xIndex} allClasses={props.allClasses} yIndex={index}/>)}
                  {(props.mode!=="EVENT") ? (<Form.Input 
                    control='input'
                    placeholder={'type here'}
                    value={text}
                    onChange={(e) => {
                      try {
                      var temp = props.tableData;
                      temp[props.xIndex][index].text=e.target.value
                      props.setTableData(temp)
                      props.setTableChange(props.tableChange+1)
                    }
                      catch (error) {
                        console.log(error)
                      }
                    }}
                  />) : <></>}
                </Table.Cell>
              </Table.Row>
            )))}
          </Table.Body>
          <Table.Footer>
          <Table.Row>
          </Table.Row>
          </Table.Footer>
          <Button size='mini' onClick={addNewItem} icon='plus'/><Button onClick={delNewItem} size='mini' icon='minus'/>
        </Table>
        </>)
    }
    
    export default ExpandYTable