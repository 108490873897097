import styles from '../styles/components/ProtectedRoute.module.css'
import { useAuthenticationStatus } from '@nhost/react'
import { Navigate, useLocation } from 'react-router-dom'
import Spinner from './Spinner'

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuthenticationStatus()
  const location = useLocation()

  if (isLoading) {
    return (
      <div className={styles.container}>
        <Spinner />
      </div>
    )
  }
  if (!isAuthenticated && (location.pathname=="/")) {
    return <Navigate to="/landing" state={{ from: location }} replace />
  } else if (!isAuthenticated &&
     (location.pathname=="/make" || location.pathname=="/flowfinder" || location.pathname=="/wishlist" || location.pathname.substring(0,9)=="/details/")) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}

export default ProtectedRoute