import _ from 'lodash'
import React from 'react'
import { Card, Container, Table, Segment } from 'semantic-ui-react'
import ProductCard from './product-card'


function sortableReducer(state, action) {
  switch (action.type) {
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          direction:
            state.direction === 'ascending' ? 'descending' : 'ascending',
        }
      }

      return {
        column: action.column,
        data: _.sortBy(state.data, [action.column]),
        direction: 'ascending',
      }
    default:
      throw new Error()
  }
}

function priceDirection (props) {
  // console.log(props.activeClass)
  if (props.activeClass===undefined) {
    return ""
  } else if (props.priceDirection === null) {
    return "Product Options (sort price)"
  } else if (props.priceDirection === "ascending") {
    return "Product Options (price low to high)"
  } else if (props.priceDirection === "descending") {
    return "Product Options (price high to low)"
  }
}


function SpecTable(props) {

  if (props.productSpecData.error) { return (<h1>ERROR loading data</h1>)};

  //get low 10
  var     sorted = props.productSpecData.sort(function comparePrice(a,b) {
    return a.price-b.price
  })

  const topFilter = 3

  if (sorted.length >= topFilter) {
    sorted = sorted.slice(0,topFilter)
  }



  return (
  <div style={{
    // overflowY: 'hidden',
    //  overflowX: 'hidden',
    //  display: 'flex',
    //  justifyContent: 'center',
    //  alignItems: 'right',
    //   borderRadius: '5px',
    //   backgroundColor: 'white',
    //   opacity:'1',
    //   border: '1px solid lightgray',
    //   boxShadow: '1px 1px #b8b8b8',
    //   paddingTop: '10px',
    }}><Card.Group style={{marginTop: '10px', minHeight:'10em', maxHeight:'25em', overflowY:'hidden', overflowX: 'auto'}} itemsPerRow={sorted.length}>
        {sorted && sorted.map(({ id, make, model, urlPurchase, nickName, photoURL, price, currency, classID }, index) => {
          return (
          <ProductCard id={id} nickName={nickName} photoURL={photoURL} single={sorted.length==1} recommend={props.recMeta} classID={classID} make={make} model={model}
            urlPurchase={urlPurchase} price={price} currency={currency} home={false}/>
        )})}
    </Card.Group></div>
  )
}

export default SpecTable