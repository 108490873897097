import {React, useState, useEffect} from 'react';
import { Image, Button, Dropdown, Segment, Grid, Header, Form } from 'semantic-ui-react'

import {RiseLoader} from 'react-spinners'

import { gql, useQuery, useMutation } from "@apollo/client";

import HomeProductTable from '../components/home-product-table'
import ProductclassQuery from '../components/product-class-query';

import styles from '../styles/components/AddProductCard.module.css';

//ADD NEW HOME PRODUCTS
//first dropdown for product class
//second dropdown for classific products

//query to find dropdown product classes
export const GET_CLASSES = gql`
  query allClasses {
    products  {
      _id
      className
    }
  }
`;

//query to find dropdown product specs
export const GET_SPECS = gql`
  query listSpec ($classID: String!) {
    specProducts(productClassID: $classID) 
    {
    _id
    make
    model
    productClass{
      _id
      className
    }
    }
  }
`;
//query for classes
function QueryClassProducts () {
const {
  loading: loadingClasses, 
  error: errorClasses, 
  data: allClasses } = useQuery(GET_CLASSES)

if (loadingClasses) { return ('Loading...')} ;
if (errorClasses) { return ('ERROR')};

return ({allClasses, loadingClasses, errorClasses}
  )}

  //query for spec products
function QuerySpecProducts (classID) {
const { 
  loading: loadingSpecs, 
  error: errorSpecs, 
  data: allSpecs } = useQuery(GET_SPECS,
    {variables: { classID }},)

if (loadingSpecs) { return ('Loading...') };
if (errorSpecs) { return ('ERROR')};

return ({allSpecs, loadingSpecs, errorSpecs}
  )}


// // Dropdown component for selecting class name for products
const DropdownClasses = (props) => {
  const productClasses = props.classProducts

  const dropdownOpts = productClasses.map(
    (prodClass) => ({
      key: prodClass._id,
      text: prodClass.className,
      value: prodClass._id
    })
  )
  const handleChange = async (p, { value }) => {
    const prod = productClasses.find(prod => prod._id === value)
    if (value === '' || value === undefined) {
    await props.setClassID({classID: "ALL"})
    } else {
    await props.setClassID({
      classID: value,
      className: prod.className
    })}
    // console.log("CLASS HANDLE CHANGE TRIGG")
    // console.log(props.classID)
  }

  return (
    <Dropdown
      placeholder={"Or Filter by Product Type"}
      selection
      search
      clearable
      options={dropdownOpts}
      noResultsMessage="No results found"
      onChange={handleChange}
    />
  );
}
// // Dropdown component for selecting class name for products
const DropdownSpec = (props) => {

    const productSpecs = props.specProducts.specProducts
  const dropdownOpts = productSpecs.map(
    (prod) => ({
      key: prod._id,
      text: `${prod.model} | ${prod.make} `,
      value: prod._id
    })
  )
  // set event name, action or trigger, and id of event selected
  const handleChange = async (p, { value }) => {
    const prod = productSpecs.find(prod => prod._id === value)
    if (value === '' || value === undefined) {
    await props.setFormInstance({})
    } else {
    await props.setFormInstance({
      specID: value,
      make: prod.make,
      model: prod.model
       // classID: prod.productClass[0]._id,
      // className: prod.productClass[0].className
      // className: props.formInstance.className,
    })
    // //write product class into dropdown class state
    // console.log(prod.productClass[0]._id)
    // await props.setClassID({
    //   classID: prod.productClass[0]._id,
    //   className: prod.productClass[0].className
    // })
    // console.log("SPEC HANDLE CHANGE TRIGG")
    // console.log(props.classID)
  }}
  return (
    <Dropdown
      placeholder="Select a Product"
      selection
      search
      clearable
      options={dropdownOpts}
      noResultsMessage="No results found, add new product"
      onChange={handleChange}
    />
  );
}


const NewProductForm = (props) => {
    
    // const [notes, setNotes] = useState("add any notes")
    const [classID, setClassID] = useState({classID: "ALL"})

    const allClasses = QueryClassProducts();
    const allSpecs = QuerySpecProducts(classID.classID);
    const photoURL = `https://raw.githubusercontent.com/sarulnat/public-assets/main/${classID.classID}.png`

return (
        <>{(!props.mobileStatus() ? <><div className={styles['image-crop']}><Image bordered src={photoURL}/></div></> : <></>)}
        <Grid columns={!props.mobileStatus() ? 2 : 1}><Grid.Column width={!props.mobileStatus() ? 10 : {}}>
        <Form>
        {(props.mobileStatus() ? <div className={styles['image-mobile']}><Image bordered src={photoURL}/></div> : <></>)}

            <Form.Group widths='equal'>
                <Segment>
                    <Grid centered>
                        <Grid.Row>
                            <Header as="h3">Select Device Type</Header>
                        </Grid.Row>
                        <Grid.Row>
                        <>✅ only add devices available for use at home<br/>
                        (similar products should work)</>
                        </Grid.Row>
<Grid.Row>
    {((allSpecs.allSpecs && (allSpecs != 'Loading...') &&
        (allSpecs != 'ERROR')) ?
        <DropdownSpec formInstance={props.formInstance} classID={classID}
         setClassID={setClassID} setFormInstance={props.setFormInstance} specProducts={allSpecs.allSpecs} />
          : <><RiseLoader color='#36d7b7'/>
          </>)}
</Grid.Row>
<Grid.Row>
{((allClasses != 'Loading...') &&
        (allClasses != 'ERROR')) ?
        <DropdownClasses formInstance={props.formInstance} classID={classID}
         setClassID={setClassID} setFormInstance={props.setFormInstance}
          classProducts={allClasses.allClasses.products} /> 
          : <><RiseLoader color='#36d7b7'/>
          </>}
</Grid.Row>
                  </Grid>
                </Segment>
            </Form.Group>
            </Form></Grid.Column></Grid></>
        )}

    export default NewProductForm