import { useState } from "react";
import { Button, Divider, Dropdown, Grid, Header, Icon, Input, Modal, Segment } from "semantic-ui-react";
import { gql, useQuery, useMutation } from "@apollo/client";

export const GET_EVENT_INPUTS = gql`
  query listEvents {
    events {
      _id
      eventtitle
      actiontrigger
    }
  }
`;

const GET_PRODUCTS = gql`
  query listProducts {
    products {
      _id
      className
    }
  }
`;

const CREATE_EVENT = gql`
  mutation AddEvent($eventInput: EventInput) {
    createEvent(eventInput: $eventInput){
      _id
  }
}
`

const initNodeEvent =
  {
    id: '1',
    eventName: 'Start',
    actionOrTrigger: ''
  }

export default function GetEventInputs(args) {
  // Query database for all event titles using GetEventInputs function
  const { loading, error, data: dbEvent } = useQuery(GET_EVENT_INPUTS)
  const { loading: dbProductLoading, error: dbProductError, data: dbProduct } = useQuery(GET_PRODUCTS)
  const [eventInput, { loading: submissionLoad, error: submissionError}] = useMutation(CREATE_EVENT,
    { refetchQueries: [{ query: GET_EVENT_INPUTS }] })

  const [newEventName, setNewEventName] = useState('')
  const [newActionOrTrigger, setNewActionOrTrigger] = useState('')
  const [productClass, setProductClass] = useState('')
  const [dbEventInfo, setDbEventInfo] = useState([[]])
  const [eventArray, setEventArray] = useState([initNodeEvent])
  const [node, setNode] = useState()
  const [showActionButton, setShowActionButton] = useState(false)
  const [showTriggerButton, setShowTriggerButton] = useState(false)
  const [open, setOpen] = useState(false)

  const setShowActionOrTrigger = (actionOrTrigger) => {
    if (actionOrTrigger === 'Action') {
      setShowActionButton(true)
      setShowTriggerButton(false)
      setNewActionOrTrigger('Action')
    } else if (actionOrTrigger === 'Trigger') {
      setShowActionButton(false)
      setShowTriggerButton(true)
      setNewActionOrTrigger('Trigger')
    }
  }
  // Add nodes to the flow and submit to databse if new event is created
  const addNode = async () => {
    let eventInfo, newEventInfoId
    if(newEventName.length > 0 && newActionOrTrigger.length > 0) {
      setDbEventInfo('')
      await eventInput(
        {
          variables: {
            eventInput: {
              eventtitle: newEventName, 
              actiontrigger: newActionOrTrigger,
              compatibleproducts: productClass.id,
              mid: args
            }
          }
        })
        // set result data as newEventInfoId
        .then(result => {
          newEventInfoId = result.data.createEvent._id
        })
        eventInfo = {
          eventName: newEventName,
          actionOrTrigger: newActionOrTrigger,
          id: newEventInfoId
        }
    } else if (dbEventInfo.eventName && dbEventInfo.actionOrTrigger) {
      eventInfo = {
        eventName: dbEventInfo.eventName,
        actionOrTrigger: dbEventInfo.actionOrTrigger,
        id: dbEventInfo.id
      }
    } else {
      alert('Add event!')
    }
    setNode(({
      id: (eventArray.length+1).toString(),
      type: 'default',
      data: { label: eventInfo.eventName },
      position: { x: 16, y: 16 }
    }))
    setEventArray([...eventArray, eventInfo])
    setOpen(false)
    setShowActionButton(false)
    setShowTriggerButton(false)
    setNewActionOrTrigger('')
    setNewEventName('')
    setDbEventInfo('')
    newEventInfoId = ''
  }

  
  
  if (loading) return "Loading..."
  if (error) return `Error! ${error.message}`
  if (submissionLoad) return 'Submitting...'
  if (submissionError) return `Submission error! ${submissionError.message}`
  if (dbProductLoading) return 'Loading...'
  if (dbProductError) return `Error! ${dbProductError.message}`

  return {
    eventArray,
    node,
    render: (
      <>
      <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}
        trigger={
          <Button basic color='teal' animated='vertical'>
          <Button.Content visible>Add Event</Button.Content>
          <Button.Content hidden>
            <Icon name='arrow up' />
          </Button.Content>
        </Button>}>
        <Modal.Header>Add Event</Modal.Header>
      <Modal.Content>
        <Grid centered>
          <Grid.Row>
            <Header as="h4">Select Action or Trigger</Header>
          </Grid.Row>
          <Grid.Row>
            <Button.Group>
              <Button 
              active={showActionButton}
              primary={showActionButton}
              secondary={!showActionButton}
              onClick={() => setShowActionOrTrigger('Action')}>Action</Button>
              <Button.Or />
              <Button 
              active={showTriggerButton}
              primary={showTriggerButton}
              secondary={!showTriggerButton}
              onClick={() => setShowActionOrTrigger('Trigger')}>Trigger</Button>
            </Button.Group>
          </Grid.Row>
          </Grid>
        {showActionButton || showTriggerButton ?
        <Segment>
          <Grid centered>
            <Grid.Row>
              <Header as="h3">Select Event</Header>
            </Grid.Row>
              <Grid.Row>
              {dbEvent && showActionButton ?
              <DropdownActions events={dbEvent.events} setDbEventInfoProps={setDbEventInfo} />: null}
              {dbEvent && showTriggerButton ?
              <DropdownTriggers events={dbEvent.events} setDbEventInfoProps={setDbEventInfo} />: null}
            </Grid.Row>
          </Grid>
        </Segment>: null}
        {showActionButton || showTriggerButton ? <>
        <Divider horizontal>Or</Divider>
        <Segment>
          <Grid centered columns={2}>
            <Grid.Row>
              <Header as="h3">Add New Event</Header>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
              <Input placeholder={showActionButton?"Create New Action":"Create New Trigger"}  
              onChange={(e) => setNewEventName(e.target.value)} fluid />
              </Grid.Column>
              <Grid.Column>
                {dbProduct ?
                <DropdownProducts products={dbProduct.products} productClassProps={setProductClass} />
                : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment></> : null}
      </Modal.Content>
      <Modal.Actions>
          <Button color='teal' onClick={e => {addNode(e)}}>Submit</Button>
        </Modal.Actions>
      </Modal>
      </>
    )
  }
}

// Dropdown component for selecting event name for actions
const DropdownActions = (props) => {
  const { events } = props
  const actionEvents = events.filter(event => event.actiontrigger === "action")

  const dropdownOpts = actionEvents.map(
    (event) => ({
      key: event.id,
      text: event.eventtitle,
      value: event.eventtitle
    })
  )

  // set event name, action or trigger, and id of event selected
  const handleChange = (e, { value }) => {
    const event = actionEvents.find(event => event.eventtitle === value)
    if (value === '' || value === undefined) {
      props.setDbEventInfoProps({})
    } else {
    props.setDbEventInfoProps({
      eventName: value,
      actionOrTrigger: event.actiontrigger,
      id: event._id
    })
  }
  }

  return (
    <Dropdown
      placeholder="Select an Action"
      selection
      search
      clearable
      options={dropdownOpts}
      noResultsMessage="No results found, add new event"
      onChange={handleChange}
    />
  )
}

// Dropdown component for selecting event name for triggers
const DropdownTriggers = (props) => {
  const { events } = props
  const triggerEvents = events.filter(event => event.actiontrigger === "trigger")

  const dropdownOpts = triggerEvents.map(
    (event) => ({
      key: event.id,
      text: event.eventtitle,
      value: event.eventtitle
    })
  )

  // set event name, action or trigger, and id of event selected
  const handleChange = (e, { value }) => {
    const event = triggerEvents.find(event => event.eventtitle === value)
    if (value === '' || value === undefined) {
      props.setDbEventInfoProps({})
    } else {
    props.setDbEventInfoProps({
      eventName: value,
      actionOrTrigger: event.actiontrigger,
      id: event._id
    })
  }
  }

  return (
    <Dropdown
      placeholder="Select a Trigger"
      selection
      search
      clearable
      options={dropdownOpts}
      noResultsMessage="No results found, add new event"
      onChange={handleChange}
    />
  );
}

// Dropdown component for selecting product
const DropdownProducts = (props) => {
  const { products } = props

  const dropdownOpts = products.map(
    (product) => ({
      key: product._id,
      text: product.className,
      value: product.className
    }))

  const handleChange = (e, { value }) => {
    if (value === '' || value === undefined) {
      props.productClassProps({})
    } else {
    props.productClassProps({
      productClass: value,
      id: products._id
    })
  }
  }
  
  return (
    <Dropdown
      placeholder="Select a compatible Product Class"
      selection
      multiple
      search
      fluid
      clearable
      options={dropdownOpts}
      noResultsMessage="No results found"
      onChange={handleChange}
    />
  )
}