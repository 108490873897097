import {React, useState, useEffect} from 'react';
import { Form, Modal, Button, Segment, FormInput, Dropdown, Header, Grid} from 'semantic-ui-react';

import { ApolloClient, gql, useQuery, useMutation } from "@apollo/client";
import { toast } from 'react-hot-toast'


const mongoose = require('mongoose')

//query to find dropdown product classes
export const GET_CLASSES = gql`
  query allClasses {
    products  {
      _id
      className
    }
  }
`;

//query to find dropdown product specs
export const GET_SPECS = gql`
  query listSpec ($classID: String!) {
    specProducts(productClassID: $classID) 
    {
    _id
    make
    model
    productClass{
      _id
      className
    }
    }
  }
`;
//query for classes
function QueryClassProducts () {
const { 
  loading: loadingClasses, 
  error: errorClasses, 
  data: allClasses } = useQuery(GET_CLASSES)

if (loadingClasses) { return ('Loading...') };
if (errorClasses) { return ('ERROR')};

return ({allClasses, loadingClasses, errorClasses}
  )}



// // Dropdown component for selecting class name for products
const DropdownClasses = (props) => {
  const productClasses = props.classProducts

  const dropdownOpts = productClasses.map(
    (prodClass) => ({
      key: prodClass._id,
      text: prodClass.className,
      value: prodClass._id
    })
  )

  const handleChange = async (p, { value }) => {

    props.setMultiTable(value)
  
  }

if (props.mode == "UPDATE") {
  var defClass = []
  props.default.forEach((prod) => {
    defClass.push(prod._id)
  })
}


  return (
    <Dropdown
      placeholder={"select product type"}
      selection
      defaultValue={props.mode=="NEW" ? null : defClass}
      search
      clearable
      multiple
      options={dropdownOpts}
      noResultsMessage="No results found"
      onChange={handleChange}
    />
    );
}
// // Dropdown component for selecting class name for products
const DropdownSpecMakeModel = (props) => {

    const productSpecs = props.specProducts.specProducts
    const dropdownOpts = productSpecs.filter((v,i,a)=>a.findIndex(v2=>((v2.model===v.model)&&(v2.make===v.make)))===i)
    .map(
    (prod) => ({
      key: prod._id,
      text: prod.model.concat(" | ".concat(prod.make)),
      value: prod._id
    })
  )
  // set event name, action or trigger, and id of event selected
  const handleChange = async (p, { value }) => {
    // props.setDSpecMake("")
    // props.setDSpecModel("")
    const prod = productSpecs.find(prod => prod._id === value)
    if (value === '' || value === undefined) {
    if (props.classID.classID!=="ALL") {

    } else {
    await props.setClassID({classID: "ALL"})}
    await props.setFormInstance({})
    } else {
    await props.setFormInstance({
      specID: value,
      make: prod.make,
      model: prod.model
    })

  }}
  return (
    <Dropdown
      placeholder="select the make / model"
      selection
      search
      clearable
      options={dropdownOpts}
      noResultsMessage="No results found, add new product"
      onChange={handleChange}
    />
  );
}

const DropdownSpecMake = (props) => {
    //accepts model name as prop and sets default to its make
    //otherwise shows selection
    const productSpecs = props.specProducts.specProducts
    const dropdownOpts = productSpecs.filter((v,i,a)=>a.findIndex(v2=>(v2.make===v.make))===i)
    .map(
    (prod) => ({
      key: prod._id,
      text: prod.make,
      value: prod.make
    })
  )
  // set event name, action or trigger, and id of event selected
  const handleChange = async (p, { value }) => {
    props.setDSpecMake(value)
//     const prod = productSpecs.find(prod => prod._id === value)
//     console.log(prod)
//     if (value === '' || value === undefined) {
//     await props.setFormInstance({})
//     } else {
//     await props.setFormInstance({
//       specID: value,
//       make: prod.make,
//       model: prod.model
//     })

//   }
}
  return (
    <Dropdown
      placeholder="select the manufacturer name"
      selection
      search
      clearable
      options={dropdownOpts}
      noResultsMessage="No results found, add new product"
      onChange={handleChange}
    />
  );
}

//price
//currency/purchaseURL
//classUnits
//multiple productClasses

const CreateProductForm = (props) => {

//query for spec products
function QuerySpecProducts (classID) {
  const { 
    loading: loadingSpecs, 
    error: errorSpecs, 
    data: allSpecs } = useQuery(GET_SPECS,
      {variables: { classID }},)
  
  if (loadingSpecs) { return ('Loading...') };
  if (errorSpecs) { return ('ERROR')};
  
  return {allSpecs}}
  
        const [open, setOpen] = useState(false)

    const [addProd, {
      loading: loadingAddProd, 
      error: errorAddProd}] = useMutation(props.gq)

      const formatClasses = (prodClass) => {
        var temp = []
        prodClass.forEach((e)=> {
          temp.push(mongoose.Types.ObjectId(e))
        })
        return temp
      }
    
  const submitProductUpdate = () => {



    try {
      let tempPrice = parseFloat(price)
      const specUpdate = {
          _id: props.specID,
          classUnits: classUnits,
          currency: currency,
          make: dSpecMake,
          model: dSpecModel,
          price: tempPrice,
          productClass: formatClasses(multiTable),
          urlPurchase: purchURL,
          photoURL: photoURL
      }
      addProd({variables:{
            specUpdate:specUpdate
      }
        })
        if (loadingAddProd) return ("Loading...")
        if (errorAddProd) return ("ERROR")
        else {
        props.refresh({ classID:"ALL" })
        setOpen(false)}
      } catch (error) {
            toast.error('Unable to update product', { id: 'updateProduct' })
        }

  }


const submitNewProduct = (args) => {
          try {

        let tempPrice = parseFloat(price)

        const inputSpec = {
            classUnits: args.classUnits,
                currency: args.currency,
                make: args.make,
                model: args.model,
                price: tempPrice,
                productClass: formatClasses(multiTable),
                // productClass:[mongoose.Types.ObjectId(args.productClass.classID)],
                urlPurchase: args.urlPurchase
        }
          addProd({variables:{
              inputSpec:inputSpec
            }
          })
          if (loadingAddProd) return ("Loading...")
          if (errorAddProd) return ("ERROR")
          else {
          setOpen(false)}
        } catch (error) {
              toast.error('Unable to add product', { id: 'addProduct' })
          }
        }

const [multiTable, setMultiTable] = useState([])

const [formInstance, setFormInstance] = useState()

const [classUnits, setClassUnits] = useState(1)
const [purchURL, setPurchURL] = useState()
const [currency, setCurrency] = useState()
const [price, setPrice] = useState()
const [dSpecModel, setDSpecModel] = useState()
const [dSpecMake, setDSpecMake] = useState()
const [photoURL, setPhotoURL] = useState()
const [classID, setClassID] = useState({classID: "ALL"})

const initVars = () => {
    setOpen(true)
    setClassUnits(1)
    setPurchURL()
    setCurrency()
    setPrice()
    setDSpecModel()
    setDSpecMake()
    setPhotoURL()
    setClassID({classID: "ALL"})
}

const initUpdateVars = () => {
  setOpen(true)
  setClassUnits(props.updateUnits)
  setPurchURL(props.updateURL)
  setCurrency(props.updateCurrency)
  setPrice(props.updatePrice)
  setDSpecModel(props.updateModel)
  setDSpecMake(props.updateMake)
  setPhotoURL(props.photoURL)
  // setClassID(props.productClass)
}

const allClasses = QueryClassProducts();

const allSpecs = QuerySpecProducts(classID.classID);

const tempAllSpecs = QuerySpecProducts("ALL");
// allSpecs.reSpecs({variables: {"ALL"}})
// if (open == false) { allSpecs.reSpecs({variables: { classID: "ALL" }})}
//only show create product based on id
return (
        <>
        <Modal
        onClose={() => setOpen(false)}
        onOpen={props.mode=="NEW" ? (() => initVars()):
      (() => initUpdateVars())}
        open={open}
        trigger={props.mode=="NEW" ? (<Button secondary>New Product</Button>) :
        (<div>{props.updateModel}</div>)}
      >
        <Modal.Header>➕ Add Product Details</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
            <Form.Group widths='equal'>
                <Segment>
                    <Grid centered>
                        <Grid.Row>
                        <Grid columns={2}>
                        <Grid.Column textAlign='right'>
                        <Grid.Row>
                         {(props.mode=="UPDATE") ? <Form.Input
                            control='input'
                            value={dSpecMake}
                            disabled
                            placeholder="make"
                            onChange={(e) => setDSpecMake(e.target.value)}
                        /> :
                        (allSpecs.allSpecs && (allSpecs != 'Loading...') &&
    (allSpecs != 'ERROR') &&
    <DropdownSpecMakeModel setDSpecMake={setDSpecMake} setDSpecModel={setDSpecModel} formInstance={formInstance} classID={classID} setClassID={setClassID} setFormInstance={setFormInstance} specProducts={allSpecs.allSpecs} />
    )}</Grid.Row>
    <Grid.Row>
    {(props.mode=="NEW") ? (
    <>
    {"    OR create a new product"}
    {allSpecs.allSpecs && 
    (allSpecs != 'Loading...') &&
    (allSpecs != 'ERROR') &&
    <DropdownSpecMake setDSpecMake={setDSpecMake} specProducts={tempAllSpecs.allSpecs} />
    }</>): (<></>)}
                        <Form.Input
                            control='input'
                            value={dSpecModel}
                            placeholder="model"
                            onChange={(e) => setDSpecModel(e.target.value)}
                        />
                    </Grid.Row>
                        </Grid.Column>
                        <Grid.Column>
                        {(allClasses != 'Loading...') &&
                                (allClasses != 'ERROR') &&
                        <DropdownClasses multiTable={multiTable} setMultiTable={setMultiTable} default={props.productClass} mode={props.mode} formInstance={formInstance} classID={classID} setClassID={setClassID} setFormInstance={setFormInstance} classProducts={allClasses.allClasses.products} />}
                        
                        </Grid.Column>
                        </Grid>
                        </Grid.Row>
                        {/* //NEED TO CHANGE CLASSES TO MULTISELECT DROPDOWN */}
                        <Grid.Row>
                        <Form.Input
                            control='input'
                            type='number'
                            min={1}
                            value={classUnits}
                            label='Units of Product Type'
                            placeholder={1}
                            onChange={(e) => setClassUnits(e.target.value)}
                        /></Grid.Row>
                        <Grid.Row>
                        <Form.Input
                            control='input'
                            type='number'
                            min={0}
                            value={price}
                            label='Price'
                            placeholder={0}
                            onChange={(e) => setPrice(e.target.value)}
                        />
                        <Form.Input
                            control='input'
                            value={currency}
                            label='Currency'
                            placeholder='CAD'
                            onChange={(e) => setCurrency(e.target.value)}
                        />
                        <Form.Input
                            control='input'
                            value={purchURL}
                            label='Purchase URL'
                            placeholder='Purchase URL'
                            onChange={(e) => setPurchURL(e.target.value)}
                        />
                    </Grid.Row>
                    <img style={{marginRight:'20px',marginLeft:'20px',objectFit: 'cover',maxHeight:'140px',borderRadius:'10px'}} bordered src={photoURL} wrapped ui={false}/>
                        <Form.Input
                            control='input'
                            value={photoURL}
                            label='Photo'
                            placeholder='Photo'
                            onChange={(e) => setPhotoURL(e.target.value)}
                        />

                    </Grid>
            </Segment>
            </Form.Group>
            </Form>        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Nahh
        </Button>
        <Button
          content="SAVE"
          labelPosition='right'
          icon='checkmark'
          onClick={(props.mode=="NEW") ? (() => submitNewProduct({
            classUnits: classUnits,
            currency: currency,
            make: dSpecMake,
            model: dSpecModel,
            price: price,
            productClass: classID,
            urlPurchase: purchURL,
            photoURL: photoURL,
        })) : (() => {
          submitProductUpdate()
        })}
          positive
        />
      </Modal.Actions>
    </Modal>
      </>
        )
        }

export default CreateProductForm