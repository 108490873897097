import React, { useState, useEffect } from 'react';
import { Button, Header, Image, Modal, Table, Icon, Form, Dropdown, Grid, Container, Dimmer, Loader, Divider} from 'semantic-ui-react';
import { gql, useQuery, useMutation } from "@apollo/client";
import NewProductForm from '../components/new-product-form';
import { Link } from 'react-router-dom';
import {PropagateLoader} from 'react-spinners'

import buttonStyles from '../styles/components/Button.module.css';
import ProductSpecQuery from '../components/product-spec-query';

import { useOutletContext } from 'react-router-dom';

import ServiceForm from '../components/service-request-form'
import SpecTable from '../components/product-spec-cards';

import HomeProductTable from '../components/home-product-table'
import HomeFlowTable from '../components/home-flow-table'
import QueryHomeProducts from '../components/home-prod-query'
import QueryHomeFlows from '../components/home-flow-query'
import PreCompQuery from '../components/product-class-query-precomp';
import ProductTable from '../components/product-table';

const ADD_PRODUCT = gql`
mutation addHomeProduct ($addProductSpec: ProductSpec) {
  addHomeProduct (productSpec: $addProductSpec) {
    _id
}}
`;


const ADD_FLOW = gql`
mutation addHomeFlow ($addFlow: FlowHome) {
  addHomeFlow (homeFlow: $addFlow) {
    _id
}}
`;

const DELETE_FLOW = gql`
mutation deleteHomeFlow ($delFlow: FlowHome) {
  deleteHomeFlow (homeFlow: $delFlow) {
    _id
}}
`;

const SERVICE_REQUEST = gql`
mutation CreateServiceRequest($srInput: SRInput) {
  createServiceRequest(srInput: $srInput) {
    _id
  }
}`;


const Wishlist = (args) => {
const { user } = useOutletContext();
const [diyStatus, setDIYStatus] = useState(false)
const [recMetaIndex, setRecMetaIndex] = useState()

const {homeFlows: testHomeFlows, loadingHomeFlows, refetchHomeFlows} = QueryHomeFlows(user.metadata.mid)

const mongoID = user.metadata.mid

const [addFlow, {
  loading: loadingAddWish, 
  error: errorAddWish}] = useMutation(ADD_FLOW)

  const [delFlow, {
    loading: loadingDelWish, 
    error: errorDelWish}] = useMutation(DELETE_FLOW)
    

    const [submitSR, {
      loading: loadingSR, 
      error: errorSR}] = useMutation(SERVICE_REQUEST)




const [activeFlow, setActiveFlow] = useState()
const changeSelection = (id) => {
  setActiveFlow(id)
}

//Mutation for adding product to home
const [addProduct, {
  loading, 
  error}] = useMutation(ADD_PRODUCT)

//OPEN ADD PRODUCT MODAL
  const [open, setOpen] = useState(false)
  const [NPformInstance, setNPformInstance] = useState();

  const [sortComboState, setSortComboState] = useState("PRICE")


//need to query for Home Flows and Home Products
//save to state

const {specRows: homeSpec, loadingSpecs: loadingHomeSpec, errorSpecs: errorHomeSpec, refetchHP} = QueryHomeProducts(mongoID)

//queries for home flows and recommended prod sets
const {allPreComp: homeRecommended, loadingPreComp, errorPreComp, refetchPreComp} = PreCompQuery("HOME", mongoID, sortComboState)

const [activeClass, setActiveClass] = useState();


const {specRows: specProdSet} = ProductSpecQuery(activeClass);

  const handleSubmit = async () => {
//convert to promise to refresh after async

  try {addProduct({
    variables: {
      addProductSpec: {
        prodSpecID: NPformInstance.specID,
        assignedUser: mongoID
      }
    }
  });
  setOpen(false);
  refetchHP({userID: mongoID})
  refetchPreComp()
} catch(error) {
      console.log(error)
    }
  }

  const refreshAlter = () => {
try {
  refetchHP({userID: mongoID})
  refetchPreComp()
} catch(err) {
console.log(err)
}

  }

let homeFlows = []
let homeProducts = []
if (homeRecommended)
 
{homeFlows = homeRecommended.flowProducts.flows}

const [width, setWidth]   = useState(window.innerWidth);
const [height, setHeight] = useState(window.innerHeight);
const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
}
useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
}, []);

const mobileStatus = () => {
  if (width<719) {
    return (true)
  } else {
    return (false)
  }
}

var srPriceMin
var srPriceMax
if (homeRecommended){
  srPriceMin=homeRecommended.flowProducts.first_meta?.opti
  srPriceMax=homeRecommended.flowProducts.first_meta?.opti
if (homeRecommended.flowProducts.second_meta?.opti && homeRecommended.flowProducts.second_meta?.length!=0 && (srPriceMin >= homeRecommended.flowProducts.second_meta?.opti)) {
  srPriceMin=homeRecommended.flowProducts.second_meta.opti
  if (homeRecommended.flowProducts.third_meta?.opti && homeRecommended.flowProducts.third_meta?.length!=0 && (srPriceMin >= homeRecommended.flowProducts.third_meta?.opti)) {
    srPriceMin=homeRecommended.flowProducts.third_meta.opti
  }
} else if (homeRecommended.flowProducts.third_meta?.opti && homeRecommended.flowProducts.third_meta?.length!=0 && (srPriceMin >= homeRecommended.flowProducts.third_meta?.opti)) {
  srPriceMin=homeRecommended.flowProducts.third_meta.opti
}


if (homeRecommended.flowProducts.second_meta?.opti && homeRecommended.flowProducts.second_meta?.length!=0 && (srPriceMax <= homeRecommended.flowProducts.second_meta?.opti)) {
  srPriceMax=homeRecommended.flowProducts.second_meta.opti
  if (homeRecommended.flowProducts.third_meta?.opti && homeRecommended.flowProducts.third_meta?.length!=0 && (srPriceMax <= homeRecommended.flowProducts.third_meta?.opti)) {
    srPriceMax=homeRecommended.flowProducts.third_meta.opti
  }
} else if (homeRecommended.flowProducts.third_meta?.opti && homeRecommended.flowProducts.third_meta?.length!=0 && (srPriceMax <= homeRecommended.flowProducts.third_meta?.opti)) {
  srPriceMax=homeRecommended.flowProducts.third_meta.opti
}

}

  return (homeRecommended ? <Grid columns={mobileStatus() ? 1 : 2}>
    <Grid.Row divided={!mobileStatus} textAlign = "center">
      <Grid.Column floated="left">
                <Grid.Row>
      <Header style={{letterSpacing:'1px'}}>FLOWS IN YOUR WISHLIST</Header></Grid.Row>
      <Divider/>
                <Grid.Row>{homeRecommended && (homeRecommended.flowProducts.flows.length>0) && <HomeFlowTable width={width} homeRecommended={homeRecommended}
                refetchFlows={refreshAlter}
                wishlist={true} delFlow={delFlow} addFlow={addFlow}
                activeFlow={activeFlow} changeSelection={changeSelection}/>}</Grid.Row>
                <Grid.Row><br></br></Grid.Row>
                {(mobileStatus()) ? <><Grid.Row></Grid.Row></> : (<Grid.Row>
    <br/><br/><Header style={{letterSpacing:'1px'}}>Recommended Flows (coming soon)</Header></Grid.Row>)}
      </Grid.Column>
    {<Grid.Column width={mobileStatus() ? '' : 6} floated='right'>   
              {(homeFlows.length==0) ? <><Grid.Row><Icon name='long arrow alternate left'/>add flows to your wishlist and find the best products</Grid.Row></> :
              <>{mobileStatus() ? (<><Grid.Row><br/></Grid.Row></>) : <><Grid.Row></Grid.Row></>}
              <Grid.Row>{mobileStatus() ? (<><br/><br/></>) : <></>}</Grid.Row>
              
              <Grid.Row>
    <div onClick={()=>{setDIYStatus(!diyStatus)}} className={buttonStyles['btn-grad-diy']}>Do-It-Yourself</div>
    </Grid.Row>
    { diyStatus && homeRecommended && <><Grid.Row>
                <ProductTable setRecMetaIndex={setRecMetaIndex} sortComboState={sortComboState} 
                setSortComboState={setSortComboState} submitSR={submitSR} mongoID={mongoID} diyStatus={diyStatus} setDIYStatus={setDIYStatus} refreshAlter={refreshAlter} mode={"WISH"}homeCL={homeSpec} sorter={sortComboState} productClassData={homeRecommended.flowProducts}
                 changeClassSelection={(id)=>{setActiveClass(id)}}/>
              </Grid.Row>
              <Grid.Row centered>
      {(diyStatus && specProdSet && activeClass) ? <SpecTable recMeta={recMetaIndex.pricedSpecs.filter(spec => spec.class==activeClass)[0]} productSpecData={specProdSet} activeClass={activeClass}/>
       : (<>
       <div style={{
      textAlign:'center',
            overflowY: 'auto',
            overflowX: 'hidden',
            alignItems: 'right',
             borderRadius: '5px',
             backgroundColor: 'white',
             opacity:'1',
             border: '1px solid lightgray',
             boxShadow: '1px 1px #b8b8b8',
             padding: '20px',
             position:'relative',
             top:'5px'

        }}><Icon size="big" name='arrow up'/><br/>
        <a style={{color: 'black', fontWeight:'900'}}>select or hover</a><br/>any product to see purchase options
        </div></>)}
        </Grid.Row><br/></>}
    <Grid.Row>
              OR</Grid.Row>
              <ServiceForm srPriceMax={srPriceMax} srPriceMin={srPriceMin} submitSR={submitSR} mid={mongoID}/>
              <Grid.Row centered>
    <div style={{position: "relative", right: 0}}>
    
    <Table compact='very' basic='very'>
    {homeSpec && 
    <HomeProductTable loadingHomeSpec={loadingHomeSpec} loadingAddWish={loadingAddWish} refetchHP={refetchHP} 
    mobileStatus={mobileStatus} mongoID={mongoID} allHomeProds={homeSpec}
     refreshHomeProds={refetchPreComp} formInstance={NPformInstance}/>}
    <Table.Footer><div style={{minHeight:'160px', textAlign:'center'}}>
      <Modal
    onClose={() => setOpen(false)}
    onOpen={() => {setOpen(true)}}
    open={open}
    trigger={<div>
      <Button style={{backgroundColor: "#2C2E3E", color: "WHITE"}} compact secondary>ADD DEVICE</Button><br/><br/><br/><br/><br/></div>}>
      <Modal.Header>Add device</Modal.Header><Modal.Content image>
        <NewProductForm mobileStatus={mobileStatus} setFormInstance={setNPformInstance} formInstance={NPformInstance}/>
        </Modal.Content>
        <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          content="Add device"
          labelPosition='right'
          icon='checkmark'
          onClick={() => {handleSubmit()}}
          positive
        />
        </Modal.Actions>
        </Modal></div>
        </Table.Footer>
        </Table></div>
        </Grid.Row></>}
      </Grid.Column>}
    </Grid.Row>
    </Grid> : <><br/><br/><br/><br/><br/><br/>
    <div style={{textAlign:'center'}}><PropagateLoader color="#0E7EBF" /><br/>
    <br/>hang on, we are analyzing every deal and device combination to save you time and money</div></>)
}

export default Wishlist;

