
import React from 'react'
import { Container, Dimmer, Loader, Table, Image, Grid, GridColumn, GridRow } from 'semantic-ui-react'
import { useQuery } from '@apollo/client';
import { gql, useMutation } from "@apollo/client";
import ProductCard from './product-card'
import FlowTable from '../components/flow-table';

import { useOutletContext } from 'react-router-dom';



  
// prodSpecID: "61d2559b7b99a44e9fdde236", assignedUser: "61b950fe72da97ecfbe96767"}

const HomeFlowTable = (props) => {
const { user } = useOutletContext();

const tempTF = props.homeRecommended.flowProducts.flows.map((f) => {
  return {
      ...f,
      id: f._id,
      wishStat: "yes"
  }
})

    return (
  <>
          {user && <FlowTable mongoID={user.metadata.mid} width={props.width} refetchFlows={props.refetchFlows} wishlist={props.wishlist}
          delFlow={props.delFlow} addFlow={props.addFlow} flowsLoaded={tempTF}
          activeFlow={props.activeFlow} changeSelection={()=>{}}/>}
  </>
)}
export default HomeFlowTable