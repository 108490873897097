import React, { useState, useEffect } from 'react';
import { Header, Button, Table, Icon } from 'semantic-ui-react'
import ProfileLabel from './profile-label'
import FlowRatings from './flow-ratings'
import FlowEdit from './flow-edit'
import { Link } from 'react-router-dom';

import { gql, useMutation } from "@apollo/client";


import { useOutletContext } from 'react-router-dom';

const ADD_FLOW = gql`
mutation addHomeFlow ($addFlow: FlowHome) {
  addHomeFlow (homeFlow: $addFlow) {
    _id
}}
`;

const DELETE_FLOW = gql`
mutation deleteHomeFlow ($delFlow: FlowHome) {
  deleteHomeFlow (homeFlow: $delFlow) {
    _id
}}
`;
//update state of wish with index, new value
const updateWish = (wishState, flowIndex, value) => {
  let newWishStat = wishState.map((wishStatus, index) => {
//"yes" or "no"
    if (index==flowIndex) {
      return value
    } else {
      return wishStatus
    }
  })
  return newWishStat
}
//takes in array of flows, base followed by number of features
const BaseFeatureTable = (props) => {
var tempBase


if (props.base.length==0 && props.features.length==1)
{ tempBase = props.features[0]
} else {
  tempBase = props.base
}
const [activeFlow, setActiveFlow] = useState(props.activeFlow)
useEffect(() => {
  const currentFlow = props.baseFeatures.find((bf) => (bf.id == props.activeFlow))
  props.changeSelection(currentFlow)
}, [])

  const holdSelected = (id) => {
    if (id === activeFlow) {
      return true
    } else {return false}
  }

  const { user } = useOutletContext();

  const [addFlow, {loading: loadingAddFlow, error: errorAddFlow}] = useMutation(ADD_FLOW)

  const [deleteFlow, { loading: loadingDeleteFlow, error: errorDeleteFlow }] = useMutation(DELETE_FLOW)

const newFlowArray = {
  id: tempBase.id,
  feature: "",
  basename: tempBase.basename,
  status: "proposed",
  description: "",
  troubleshoot: "",
  roomtags: "",
  functiontags: "",
  ratings: tempBase.ratings
}

// const baseWishArray = tempBase.map((flow) => {
//   return flow.wishStat
// })

const featureWishArray = props.features.map((flow) => {
  return flow.wishStat
})

const [baseWish, setBaseWish] = useState(tempBase.wishStat)
const [featureWish, setFeatureWish] = useState(featureWishArray)

const handleAddWish = (id, index) => {
  try {
    setFeatureWish(updateWish(featureWish,index,"yes"));
    addFlow({variables: {addFlow: {flowID: id, assignedUser: user.metadata.mid}}})
  } catch (error) {
    console.log(error)
    throw error
  }
}

const handleDelWish = (id, index) => {
  try {
  setFeatureWish(updateWish(featureWish,index,"no"));
  deleteFlow({variables: {delFlow: {flowID: id, assignedUser: user.metadata.mid}}})
} catch (error) {
  console.log(error)
  throw error
}
}
console.log(props)
  return (
  <div>
  <Table attached='top' basic='very' selectable>
    {(props.width>640) ? (<Table.Header>
      <Table.Row>
        <Table.HeaderCell style={{width: '10%'}}></Table.HeaderCell>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
        <Table.HeaderCell style={{width: '40%'}}>Details</Table.HeaderCell>
      </Table.Row>
    </Table.Header>) : <></>}

    <Table.Body>
      {(tempBase && tempBase.length!==0) ? <><Table.Row onClick = {() => {setActiveFlow(tempBase.id);props.changeSelection(tempBase)}}
           active = {holdSelected(tempBase.id)}>
        <Table.HeaderCell><Button size={'medium'} basic onClick={() => {
          setBaseWish(!baseWish);
    ((baseWish=="yes") ? deleteFlow({variables: {delFlow: {flowID: tempBase.id, assignedUser: user.metadata.mid}}})
   : addFlow({variables: {addFlow: {flowID: tempBase.id, assignedUser: user.metadata.mid}}}
    ))
  }
  
  } circular icon>{baseWish=="yes" ? <Icon name='heart'/>
    : <Icon name='heart outline'/>}</Button></Table.HeaderCell>
        <Table.Cell>
          {(holdSelected(tempBase.id) && (user.id == "374fd833-f742-4183-97a7-f0d135c08e3b"))? (<FlowEdit 
          feature={tempBase.basename} refetch={props.refetch}
          flowArray={tempBase}/>):tempBase.basename}<Header>BASE</Header>
        </Table.Cell>
        <Table.Cell><FlowRatings ratingsArray={tempBase.ratings}/><ProfileLabel userInfo={tempBase.makerAlias}/></Table.Cell>
        <Table.Cell>{tempBase.description}</Table.Cell>
      </Table.Row>
      <br></br>
      <Table.Row>
        <Table.HeaderCell style={{width: '10%'}}></Table.HeaderCell>
        <Table.HeaderCell>FEATURES ({props.features.length})</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
        <Table.HeaderCell style={{width: '40%'}}></Table.HeaderCell>
      </Table.Row></> : <></>}
      {props.features && props.features.map(({ id, description, status, wishStat, feature, makerMid, ratings}, index, array) => (
          <Table.Row key={index} onClick = {() => {setActiveFlow(id);props.changeSelection(array[index])}}
           active = {holdSelected(id)}>
          <Table.Cell>
            <Button size={'medium'} basic onClick={() => {
              setFeatureWish(updateWish(featureWish,index,featureWish[index]));
    (featureWish[index]=="yes") ? handleDelWish(id,index)
   : handleAddWish(id,index)}} circular icon>
    {featureWish[index]=="yes" ? <Icon name='heart'/>
    : <Icon name='heart outline'/>}</Button></Table.Cell>
          <Table.Cell>
          {(holdSelected(id) && (user.id == "374fd833-f742-4183-97a7-f0d135c08e3b")) ? (<FlowEdit mongoID={user.metadata.mid} refetch={props.refetch} feature={feature} flowArray={array[index]}/>):feature}
          </Table.Cell>
          <Table.Cell><FlowRatings ratingsArray={ratings} /><ProfileLabel userInfo={makerMid}/></Table.Cell>
          <Table.Cell>{(props.base.length==0 && props.features.length==1) ? ("*same as base") : <></>}<div style={{maxHeight:'6em', overflowX:'auto'}}>{description}</div></Table.Cell>
          </Table.Row>
        ))}
        </Table.Body>
  </Table>
  <Table attached='bottom' basic='very'>
        <Table.Row>
        <Table.Cell></Table.Cell>
        <Table.Cell>
          <FlowEdit refetch={props.refetch} mongoID={user.metadata.mid} mode="NEW REQUEST" flowArray={newFlowArray}/>
        </Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
        </Table.Row>
  </Table>
  </div>

)
      }

export default BaseFeatureTable