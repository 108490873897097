import styles from '../styles/pages/Profile.module.css';

import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useOutletContext, useNavigate } from 'react-router-dom';
import Input from '../components/Input';

import { gql, useMutation } from '@apollo/client'
import { toast } from 'react-hot-toast'



const UPDATE_USER_MUTATION = gql`
  mutation ($id: uuid!, $displayName: String!, $metadata: jsonb) {
    updateUser(pk_columns: { id: $id }, _set: { displayName: $displayName, metadata: $metadata }) {
      id
      displayName
      metadata
    }
  }
`

const Profile = () => {
  const { user } = useOutletContext();
  const navigate = useNavigate()
  let tempUser
  let updateText = 'Update successful!'
  if (user.displayName) {
    if (!user.metadata?.firstName) {
      updateText = 'Start building!'
    }
    tempUser={
      firstName: user.displayName.split(" ")[0],
      lastName: user.displayName.split(" ")[1]
    }
  } else {
    tempUser={
      firstName: (user?.metadata?.firstName || ""),
      lastName: (user?.metadata?.lastName || "")
    }
  }
  const [firstName, setFirstName] = useState(tempUser.firstName);
  const [lastName, setLastName] = useState(tempUser.lastName);

  const isFirstNameDirty = firstName !== user?.metadata?.firstName;
  const isLastNameDirty = lastName !== user?.metadata?.lastName;
  const isProfileFormDirty = isFirstNameDirty || isLastNameDirty;

  const [mutateUser, { loading: updatingProfile }] = useMutation(UPDATE_USER_MUTATION)

  const updateUserProfile = async e => {
    e.preventDefault();
    try {
      await mutateUser({
        variables: {
          id: user.id,
          displayName: `${firstName} ${lastName}`.trim(),
          metadata: {
            firstName,
            lastName,
            "mid": user.metadata.mid
          }
        },
        onCompleted: () => {
          navigate("/")
          // return (<Navigate to="/" replace={true}/>)
        }
      })

      toast.success(updateText, { id: 'updateProfile' })

    } catch (error) {
      toast.error('Unable to update profile', { id: 'updateProfile' })
    }
  };

  return (
    <>
      <Helmet>
        <title>Profile - nuHome</title>
      </Helmet>

      <div className={styles.container}>
        <div className={styles.info}>
          {(user.metadata.firstName == "" || (typeof user.metadata.firstName == 'undefined'))
           ? (<><h2>Welcome 🤙</h2><p>Get ready to build your smart home</p>
           <p>Introduce yourself</p></>)
           : (<><h2>Profile</h2><p>Update your personal information.</p></>) }
        </div>

        <div className={styles.card}>
          <form onSubmit={updateUserProfile} className={styles.form}>
            <div className={styles['form-fields']}>
              <div className={styles['input-group']}>
                <Input
                  type="text"
                  label="First name*"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  required
                />
                <Input
                  type="text"
                  label="Last name"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
              </div>
              <div className={styles['input-email-wrapper']}>
                <Input
                  type="email"
                  label="Email address"
                  value={user?.email}
                  readOnly
                />
              </div>
            </div>
            <div className={styles['form-footer']}>
              <button
                type="submit"
                disabled={!isProfileFormDirty}
                className={styles.button}
              >
              {(user.metadata.firstName == "" || (typeof user.metadata.firstName == 'undefined'))
              ? <>Start</> : <>Update</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Profile;
