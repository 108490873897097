import React from 'react'
import { Table, Header, Loader, Image, Card, Container } from 'semantic-ui-react'
import { useQuery } from '@apollo/client';
import { gql, useMutation } from "@apollo/client";
import ProductCard from './product-card'
import HomeRoof from '../assets/roof.png';
import HomeFloor from '../assets/bottomFloor.png';

//add add new product mutations in here too
const DELETE_PRODUCT = gql`
mutation deleteHomeProduct($delProductSpec: ProductSpec) {
  deleteHomeProduct (productSpec: $delProductSpec) {
    _id
}}
`;
  
const HomeProductTable = (props) => {

  const [deleteProduct, {
        loading: loadingDeleteProduct, 
        error}] = useMutation(DELETE_PRODUCT)
      
// console.log(props.allHomeProds)
  const prodsLoaded = props.allHomeProds.map(spec => {
    return {
    id: spec._id,
    make: spec.make,
    model: spec.model,
    photoURL: spec.photoURL,
    nickName: spec.nickName,
    className: spec.productClass[0].className,
    classID: spec.productClass[0]._id
    };});

    let uniqueProds = []
    prodsLoaded.forEach((p) => {
      let dupliF = 0
      if (uniqueProds.length == 0)
        { uniqueProds.push(p) }
      else
      {uniqueProds.forEach((u) => {
        if (u.id == p.id)
        {
          dupliF = 1
        }
      })
      if (dupliF == 0)
      {
        uniqueProds.push(p)
      }}
    })

  
{/* <div style={{scale: '1.7', position: "absolute",bottom: 150, zIndex:'-9'}}>
    <Image size='huge' src={HomeFloor} /></div> */}
    return (
  <>
  <div style={{scale: '1.7', position: "relative",top: 200, left:-10, zIndex:'-9'}}>
    <Image size='huge' src={HomeRoof} />
    </div>
    {(uniqueProds && uniqueProds.length==0) ? (<div 
    style={{display: 'flex',
      justifyContent: 'center',
      borderRadius: '5px%',
      alignItems: 'center', backgroundColor: 'white',
      opacity:'1',
      border: '1px solid lightgray', textAlign:'center',
      minHeight: '150px', padding: '50px'}}>
        add products you have at home to update recommendations above<br/>
        </div>) : (<><div style={{fontSize:'16px', fontWeight: 'bold', textAlign:"center"}}><Header style={{letterSpacing:'1px'}}>AVAILABLE HOME DEVICES
        {(props.loadingHomeSpec || props.loadingAddWish || loadingDeleteProduct) ? <Loader size='tiny' active inline /> : <></>}
</Header></div>
<Card.Group centered itemsPerRow={1}>

    {uniqueProds && uniqueProds.map(({ id, make, model, className, nickName, classID, photoURL}, index) => (
          <ProductCard photoURL={photoURL} nickName={nickName} classID={classID} refetchHP={props.refetchHP} width={props.width} mongoID={props.mongoID} id = {id} make={make} model={model} className={className}
          extra={false} home={true} deleteProduct={deleteProduct}
          refreshHomeProds={props.refreshHomeProds} loadingDeleteProduct={props.loadingDeleteProduct}
          prodsLoaded={prodsLoaded}/>
        ))}
    </Card.Group></>)}
    </>
)}
export default HomeProductTable