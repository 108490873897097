import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

  //query productID and get required details
  const GET_PRODUCT_INFO = gql`
  query findProductInfo ($productIDs: [String!]) {
  findProduct(productID:$productIDs){
    _id
    className
  }
}
`;

//with the target product set this function populates, required fields based on product ID


function QueryProductsTable (productIDs) {
//convert to function that takes array of [products],
//and outputs an array of objects [{id, classname}]
// console.log(productIDs)
  const { 
      loading: loadingProductInfo, 
      error: errorProductInfo, 
      data: productInfo,
      refetch: refetchProductInfo } = useQuery(GET_PRODUCT_INFO, {variables:{productIDs}})

    if (loadingProductInfo) { return ('Loading...') };
    if (errorProductInfo) { return ('ERROR')};
    
    // console.log(productInfo.findProduct)

    const productRows = productInfo.findProduct.map(product => {
      return {
      id: product._id,
      className: product.className,
      };});
    return (
      {productRows, loadingProductInfo, errorProductInfo, refetchProductInfo}
    )}

export default QueryProductsTable