import React, { useState, useEffect } from 'react';
import styles from '../styles/components/Button.module.css';
import { Button, Header, Image, Popup, Table, Icon, Form, Dropdown, Grid, Container} from 'semantic-ui-react';

const SpecProductPopup = (props) => {
    return (
        <Popup
        position='top center'
        trigger={<div className={styles["hoverPoint"]}>{props.className} {props.hClasses.includes(props.id) ? <Icon name='check circle'/> : <></>}</div>}
      >
        <Popup.Header>Best Option</Popup.Header>
        <Popup.Content>
        <a target="_blank" rel="noopener noreferrer">
        
        ${Intl.NumberFormat('en-US',
         {minimumFractionDigits: 2,
          maximumFractionDigits: 2}).format(props.combo.price)} ({(new URL(props.combo.urlPurchase)).hostname.replace('www.','')})
      </a>
            <div>Make: {props.combo.make}</div>
            <div>Model: {props.combo.model}</div>
        </Popup.Content>
      </Popup>)
}

const MenuPopup = (props) => {

return (
<Popup
        position='top center'
        trigger={<div>{props.trigger}</div>}
      >
        {props.sort=="PRICE" ? (<Popup.Header>
          Device Count: {props.count}
          </Popup.Header>)
         : (<><Popup.Header>
          Count: {props.count} {(props.count!=props.opti ? <a style={{color:'black', fontSize:'14px'}}>(minus {props.count-props.opti} @ 🏠)</a> :
          <></>)}
          </Popup.Header>
                  <Popup.Content>
                  <Icon name='shop' />
                  Total Price: ${Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(props.price)}
                  </Popup.Content></>
          )}

      </Popup>)

}

const SpecPhotoPopup = (props) => {
  return (
      <Popup
      position='top center'
      trigger={props.nickName ? <>{props.nickName}</> : <div>{props.model}</div>}
    >
      <Popup.Content>
      <Image src={props.photoURL} size='medium' />
      </Popup.Content>
    </Popup>)
}

const SpecTitle = (props) => {
  return (
      <Popup
      position='top center'
      trigger={<div>{props.nickName.split("-")[1].trim().split(" ")[0]} / {props.nickName.split("-")[0].trim()}</div>}
    >
      <Popup.Content>
      <div>{props.nickName}</div>
      </Popup.Content>
    </Popup>)
}
export {SpecTitle, SpecPhotoPopup, SpecProductPopup, MenuPopup}