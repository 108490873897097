import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

const GET_BASE_FEATURES = gql`
query filterFunction ($flowID: String!, $mid: String!) {
  findFlowDetails ( flowID: $flowID, mid: $mid) {
    _id
    basename
    feature
    functiontags
    description
    troubleshoot
    roomtags
    status
    wishStat
    eventDeps {
      _id
      nextID {
        _id
        eventtitle
        actiontrigger
      }
      eventID {
        _id
      }
    }
    ratings{
      _id
      degree
      criteria
    }
    createdby{
    _id
    }
  }
}
`;

function QueryBaseFeatures (flowID, mid) {
  
  const { 
      loading: loadingBaseFeatures, 
      error: errorBaseFeatures, 
      data, refetch: refetchBF } = useQuery(GET_BASE_FEATURES,
        {variables: { flowID, mid }},)
    
    if (loadingBaseFeatures) { return ('Loading...') };
    if (errorBaseFeatures) { return ('ERROR')};

    const baseFeatures = data.findFlowDetails.map(flow => {
      return {
      id: flow._id,
      basename: flow.basename,
      feature: flow.feature,
      description: flow.description,
      troubleshoot: flow.troubleshoot,
      ratings: flow.ratings,
      status: flow.status,
      roomtags: flow.roomtags,
      functiontags: flow.functiontags,
      makerMid: flow.createdby._id,
      eventDeps: flow.eventDeps,
      wishStat: flow.wishStat
      };});

      const tempBF = baseFeatures.filter((bf) => (bf.status != "proposed"))
      // console.log(tempBF)

    return (
      {baseFeatures: tempBF, loadingBaseFeatures, errorBaseFeatures, refetchBF}
    )}

export default QueryBaseFeatures